import { InputLabel, Typography } from "@suraasa/placebo-ui"
import Card from "components/shared/Card"
import { useContext } from "react"
import { ApplicationContext } from "views/application/masters-in-education/context/MEdContext"

import { findOptionFromResponse, SLUGS } from "./utils"

const ProfessionalFitnessDetails = () => {
  const { questionList } = useContext(ApplicationContext)

  const dynamicQuestions = questionList.filter(
    ({ question: { category } }) => category === SLUGS.step10.slug
  )

  return (
    <Card className="mt-4 p-2">
      <Typography variant="title3">Professional Fitness</Typography>
      <div className="flex flex-col gap-3 ">
        {dynamicQuestions.map(({ question }, i) => {
          const response = findOptionFromResponse(
            question.options,
            question.responses[0]
          )
          const additionalAnswer = question.responses[0].additionalAnswer

          return (
            <div key={i} className="flex flex-col">
              <div className="flex justify-between gap-1">
                <InputLabel label={question.text} />
                <Typography variant="strong" color="primary.500">
                  {response?.text}
                </Typography>
              </div>
              {additionalAnswer && (
                <Typography variant="smallBody">{additionalAnswer}</Typography>
              )}
            </div>
          )
        })}
      </div>
    </Card>
  )
}

export default ProfessionalFitnessDetails
