import {
  Avatar,
  Button,
  Pagination,
  Table,
  TableFilter,
  Typography,
} from "@suraasa/placebo-ui"
import api from "api"
import {
  ApplicationTypeSlug,
  ReviewStatus,
  Users,
} from "api/resources/applications/types"
import { PaginatedResponse } from "api/types"
import clsx from "clsx"
import NoDataCard from "components/shared/NoDataCard"
import TableWrapper from "components/shared/TableWrapper"
import { format } from "date-fns"
import { Check } from "iconoir-react"
import React, { useEffect, useState } from "react"
import { createPortal } from "react-dom"
import { useNavigate } from "react-router-dom"
import { applicationFormSlugs } from "utils/constants"
import useFirstRender from "utils/hooks/useFirstRender"
import useSearchField from "utils/hooks/useSearchField"

const NewApplications = () => {
  const isFirstRender = useFirstRender()

  const { SearchField, searchText: search } = useSearchField({
    className: "-mt-2",
  })

  const [page, setPage] = React.useState(1)
  const [loading, setLoading] = useState(false)

  const [sorts, setSorts] = useState<string[]>(["-submit_time"])
  const [filters, setFilters] = useState<TableFilter[]>([])

  const [btnLoading, setBtnLoading] = useState<number | null>(null)
  const [applications, setApplications] = useState<
    PaginatedResponse<Users[]>["data"]
  >({
    data: [],
    nextPage: null,
    previousPage: null,
    total: 0,
  })
  const navigate = useNavigate()

  const onStartReview = async (
    applicationId: number,
    type: ApplicationTypeSlug
  ) => {
    if (type === applicationFormSlugs.med) {
      navigate(`/dashboard/view/masters-in-education/${applicationId}`)
      setBtnLoading(null)
      return
    }
    const res = await api.applications.review.getOrCreate({
      urlParams: { applicationId },
      data: {
        application: applicationId,
        status: ReviewStatus.REVIEW_STARTED,
      },
    })
    if (res.isSuccessful) {
      navigate(`/dashboard/view/${applicationId}`)

      setBtnLoading(null)
    } else {
      console.error(res.errors)
      setBtnLoading(null)
    }
  }

  const getData = async (page: number) => {
    setLoading(true)
    setPage(page)
    setApplications(prev => ({ ...prev, data: [] }))

    const evidenceUploadedFilters = (() => {
      const relevantFilters = filters.filter(x => x.id === "evidence_uploaded")
      if (relevantFilters.length > 1) {
        return null
      }

      return relevantFilters.map(x => x.value)
    })()

    const res = await api.applications.applications({
      params: {
        status: "new",
        page,
        ordering: sorts,
        review_status: filters
          .filter(x => x.id === "review_status")
          .map(x => x.value),
        evidence_uploaded: evidenceUploadedFilters,
        ...(search && { search }),
      },
    })

    if (res.isSuccessful) {
      setApplications(res.data)
      // console.log(res.data)
    }
    setLoading(false)
  }

  useEffect(() => {
    getData(1)
  }, [filters, sorts, search])

  const portalEl = document.querySelector("#search-field-portal")

  return (
    <>
      {Boolean(portalEl) && createPortal(SearchField, portalEl!)}
      <TableWrapper
        content={(className, nameField) => (
          <Table
            className={className}
            data={applications.data}
            onSort={x => {
              if (isFirstRender) return
              setSorts(x)
            }}
            onFilter={x => {
              if (isFirstRender) return
              setFilters(x)
            }}
            headers={[
              {
                title: "Name",
                id: "full_name",
                sortable: true,
                align: "left",
                columnComponent: ({ row }) => (
                  <td>
                    <div className={clsx(nameField, "flex items-center")}>
                      <div className="shrink-0">
                        <Avatar
                          color="two"
                          name={`${row.applicationDetail?.firstName} ${row.applicationDetail?.lastName}`}
                          src={row.applicationDetail?.photo}
                        />
                      </div>
                      <div className="ml-1">
                        <Typography variant="strong">
                          {row.applicationDetail?.firstName}{" "}
                          {row.applicationDetail?.lastName}
                        </Typography>
                        <Typography variant="smallBody">
                          {row.applicationDetail?.email}
                        </Typography>
                      </div>
                    </div>
                  </td>
                ),
              },
              {
                title: "Application Date",
                id: "submit_time",
                sortable: true,
                align: "left",
                columnComponent: ({ row }) => (
                  <td>
                    {row.submitTime && (
                      <Typography variant="body">
                        {format(new Date(row.submitTime), "d MMMM yyyy")}
                      </Typography>
                    )}
                  </td>
                ),
              },
              {
                title: "Documents Uploaded",
                id: "evidence_uploaded",
                filters: [
                  {
                    displayName: "Uploaded",
                    value: "true",
                  },
                  {
                    displayName: "Not Uploaded",
                    value: "false",
                  },
                ],
                columnComponent: ({ row }) => {
                  return <td>{row.evidenceUploaded ? <Check /> : "-"}</td>
                },
              },
              {
                title: "",
                id: "action",
                columnComponent: ({ row }) => (
                  <td>
                    <Button
                      loading={btnLoading === row.id}
                      variant="text"
                      onClick={() => {
                        setBtnLoading(row.id)
                        onStartReview(row.id, row.productSlug)
                      }}
                    >
                      Start
                    </Button>
                  </td>
                ),
              },
            ]}
            loading={loading}
          />
        )}
      />
      {applications.data.length === 0 && !loading && (
        <NoDataCard message="No data available" />
      )}

      {applications.total > 10 && (
        <Pagination
          className="mb-2"
          page={page}
          total={applications.total}
          onChange={getData}
        />
      )}
    </>
  )
}

export default NewApplications
