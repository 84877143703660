import { Button } from "@suraasa/placebo-ui"
import React, { useCallback } from "react"
import { useNavigate } from "react-router-dom"
import { ArrowLeft } from "utils/icons"

export type BackButtonProps = {
  onBack?: () => void
  className?: string
  disabled?: boolean
}

const BackButton = ({
  className,
  onBack,
  disabled = false,
  ...otherProps
}: BackButtonProps) => {
  const navigate = useNavigate()

  const handleClick = useCallback(() => {
    if (onBack) onBack()
    else if (window.history.state !== null && window.history.state.idx > 1)
      navigate(-1)
    else navigate("/")
  }, [navigate, onBack])

  return (
    <Button
      className={className}
      disabled={disabled}
      startAdornment={<ArrowLeft />}
      variant="text"
      onClick={handleClick}
      {...otherProps}
    >
      Back
    </Button>
  )
}

export default BackButton
