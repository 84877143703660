import { InputLabel, Select, Typography } from "@suraasa/placebo-ui"
import Card from "components/shared/Card"
import { useContext } from "react"
import { ApplicationContext } from "views/application/masters-in-education/context/MEdContext"

import { learnFromOptions, SLUGS } from "./utils"

const OthersCard = () => {
  const { questionList } = useContext(ApplicationContext)

  const dynamicQuestions = questionList.filter(
    ({ question: { category } }) => category === SLUGS.step9.slug
  )
  const howDidYouLearnQuestion = dynamicQuestions.find(
    ({ question: { slug } }) => slug === SLUGS.step9.howDidYouLearn.slug
  )?.question
  const response = howDidYouLearnQuestion?.options.find(item =>
    howDidYouLearnQuestion.responses.find(res => res.option === item.id)
  )
  return (
    <Card className="mt-4 p-2">
      <Typography variant="title3">Other Information</Typography>
      <div className="flex flex-col gap-3 md:w-1/2">
        <div>
          <InputLabel label="How did you learn about this Master's degree program?" />
          <Select
            options={learnFromOptions}
            value={{ label: response?.text, value: response?.id }}
            menuIsOpen={false}
            fullWidth
            isDisabled
          />
        </div>
      </div>
    </Card>
  )
}

export default OthersCard
