import React, { useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"

type CallableJSX = (...args: any) => JSX.Element

export type Tab<C = CallableJSX> = {
  name: string
  content: C
  count?: number
  startAdornment?: React.SVGProps<SVGSVGElement>
} & Record<any, any>
export type Tabs<C = CallableJSX> = Tab<C>[]

const useTabs = <C = CallableJSX>({
  initialTab,
  tabs,
  searchParamKey = "tab",
}: {
  initialTab?: string
  tabs: Tabs<C>
  searchParamKey?: string
}) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [currentTab, setCurrentTab] = useState(
    (searchParams.get(searchParamKey) || initialTab) ?? tabs[0].name
  )

  useEffect(() => {
    if (currentTab) {
      setSearchParams(
        {
          ...Object.fromEntries(new URLSearchParams(window.location.search)),
          [searchParamKey]: currentTab,
        },
        { replace: true }
      )
    }
  }, [currentTab, searchParamKey, setSearchParams])

  useEffect(() => {
    const updatedSearchParam = searchParams.get(searchParamKey)
    if (updatedSearchParam) {
      setCurrentTab(updatedSearchParam)
    }
  }, [searchParamKey, searchParams])

  return [
    tabs.find(tab => tab.name === currentTab) || tabs[0],
    setCurrentTab,
  ] as const
}

export default useTabs
