import "./index.css"

import * as Sentry from "@sentry/react"
import SentryErrorPage from "components/shared/SentryErrorPage"
import React from "react"
import { getAuthInfo } from "utils/auth"
import { checkEnvVars } from "utils/helpers"

import { version } from "../package.json"
import App from "./App"

checkEnvVars(["VITE_API_ENDPOINT"])

if (import.meta.env.VITE_SENTRY_DSN) {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    release: version,
  })
  const authInfo = getAuthInfo()
  if (authInfo)
    Sentry.setUser({ id: authInfo.user.uuid, email: authInfo.user.email })
}
import { createRoot } from "react-dom/client"
const container = createRoot(document.getElementById("root") as HTMLElement)

container.render(
  <Sentry.ErrorBoundary
    fallback={
      <SentryErrorPage error="An unexpected error occurred. Please try reloading the page." />
    }
  >
    <App />
  </Sentry.ErrorBoundary>
)
