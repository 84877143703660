import { Container, Typography } from "@suraasa/placebo-ui"
import { format } from "date-fns"
import { useContext } from "react"
import { ApplicationContext } from "views/application/masters-in-education/context/MEdContext"

const ApplicantBar = () => {
  const { applicantData: data, submitTime } = useContext(ApplicationContext)
  return (
    <div className="sticky top-0 z-10 bg-white shadow-sm">
      <Container>
        <div className="flex flex-col gap-1 py-2 md:flex-row md:items-center md:justify-between md:py-3">
          <Typography variant="title3">
            {[data.firstName, data.lastName].join(" ")}{" "}
          </Typography>
          <div className="flex items-center gap-1">
            <Typography variant="body">Declaration Date</Typography>
            <Typography variant="strong">
              {submitTime && format(new Date(submitTime), "d MMMM yyyy")}
            </Typography>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default ApplicantBar
