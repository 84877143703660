import { Typography } from "@suraasa/placebo-ui"
import { AttachmentType } from "api/resources/global/types"
import Card from "components/shared/Card"
import React, { useContext } from "react"
import { formatDate } from "utils/helpers"
import { ApplicationContext } from "views/application/masters-in-education/context/MEdContext"

import AttachmentItem from "./Attachment"

const EducationCard = () => {
  const { qualifications, certifications } = useContext(ApplicationContext)

  return (
    <>
      <Card className="mt-4 p-2">
        <Typography className="" variant="title3">
          Educational Background
        </Typography>
        <div className="flex flex-col gap-3">
          {qualifications.length === 0 && certifications.length === 0 && (
            <Typography className="mb-2" textAlign="center" variant="strong">
              No Data Available
            </Typography>
          )}
          {qualifications.length > 0 && (
            <div className="flex flex-col gap-2">
              {qualifications.map((data, i) => (
                <div
                  className="flex flex-col justify-between gap-1 md:flex-row"
                  key={i}
                >
                  <div>
                    <Typography color="onSurface.800" variant="strong">
                      {data.name}
                    </Typography>

                    <Typography color="onSurface.800" variant="body">
                      {data.qualificationLevel && data.qualificationLevel.name}{" "}
                      <span>&#8226;</span>{" "}
                      {data.qualificationField && data.qualificationField.name}
                    </Typography>

                    <Typography color="onSurface.500" variant="smallBody">
                      {[
                        [
                          [data.instituteName],
                          [data.state?.name, data.country?.name]
                            .filter(Boolean)
                            .join(", "),
                        ]
                          .filter(Boolean)
                          .join(" • "),
                      ]}
                    </Typography>
                    <Typography color="onSurface.500" variant="smallBody">
                      {formatDate(data.startDate, "MMMM yyyy")}

                      {data.endDate && (
                        <>
                          <span className="mx-1">-</span>
                          {formatDate(data.endDate, "MMMM yyyy")}
                        </>
                      )}
                    </Typography>
                  </div>
                  {data.certificates.length > 0 && (
                    <div className="flex flex-col gap-2">
                      {data.certificates.map((item, i) => (
                        <div
                          className="flex w-full flex-col justify-between gap-1 md:flex-row md:items-center"
                          key={i}
                        >
                          {item.attachmentType && (
                            <div className="md:w-13 md:text-end">
                              <Typography
                                color="onSurface.600"
                                variant="preTitle"
                              >
                                {item.attachmentType ===
                                  AttachmentType.DEGREE && "DEGREE"}
                                {item.attachmentType ===
                                  AttachmentType.MARKSHEET && "TRANSCRIPT"}
                              </Typography>
                            </div>
                          )}
                          <div className="flex-1">
                            <AttachmentItem
                              attachmentName={item.attachmentName}
                              link={item.attachment}
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
          {certifications.length > 0 && (
            <div className="flex flex-col gap-2">
              {certifications.map((data, i) => (
                <div
                  className="flex flex-col justify-between gap-1 md:flex-row"
                  key={i}
                >
                  <div>
                    <Typography color="onSurface.800" variant="strong">
                      {data.name}
                    </Typography>
                    <Typography color="onSurface.800" variant="body">
                      {data.instituteName}
                    </Typography>
                    <Typography color="onSurface.500" variant="smallBody">
                      {formatDate(data.completionDate, "MMMM yyyy")}
                      {data.expirationDate &&
                        data.expirationDate &&
                        ` - ${formatDate(data.expirationDate, "MMMM yyyy")}`}
                    </Typography>
                  </div>
                  {data.certificates.length > 0 && (
                    <div className="flex flex-col gap-1">
                      {data.certificates.map((item, i) => (
                        <div
                          className="flex w-full items-center justify-between gap-1"
                          key={i}
                        >
                          <div className="flex-1">
                            <AttachmentItem
                              attachmentName={item.attachmentName}
                              link={item.attachment}
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      </Card>
    </>
  )
}
export default EducationCard
