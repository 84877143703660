import { createUseStyles } from "react-jss"

const useStyles = createUseStyles(theme => ({
  table: {
    background: "#FFFFFF",
    border: `1px solid ${theme.colors.surface[200]}`,
    borderRadius: " 8px 8px 0px 0px",
    marginBottom: theme.spacing(1),
    "& thead": {
      borderBottom: `1px solid ${theme.colors.surface[200]}`,
      color: "#64748B",
      fontSize: "16px",
      height: "60px",
    },
    "& td, th": {
      padding: theme.spacing(2),
    },
    "& th:first-child": {
      paddingLeft: "70px",
    },
    "& tr": {
      height: "60px",
      borderBottom: `1px solid ${theme.colors.surface[200]}`,
    },
  },
  nameField: {
    height: "60px",
    padding: theme.spacing(2),
  },
}))

const TableWrapper = ({
  content,
}: {
  content: (className: string, nameField: string) => JSX.Element
}) => {
  const classes = useStyles()
  return content(classes.table, classes.nameField)
}

export default TableWrapper
