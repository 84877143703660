import {
  Avatar,
  Button,
  Pagination,
  Table,
  TableFilter,
  Tag,
  Typography,
} from "@suraasa/placebo-ui"
import api from "api"
import { Users } from "api/resources/applications/types"
import { PaginatedResponse } from "api/types"
import clsx from "clsx"
import NoDataCard from "components/shared/NoDataCard"
import TableWrapper from "components/shared/TableWrapper"
import { format } from "date-fns"
import { Check } from "iconoir-react"
import React, { useEffect, useState } from "react"
import { createPortal } from "react-dom"
import { Link } from "react-router-dom"
import { getApplicationTag } from "utils/helpers"
import useFirstRender from "utils/hooks/useFirstRender"
import useSearchField from "utils/hooks/useSearchField"

const PreviousApplications = () => {
  const isFirstRender = useFirstRender()

  const { SearchField, searchText: search } = useSearchField({
    className: "-mt-2",
  })

  const [loading, setLoading] = useState(false)
  const [page, setPage] = React.useState(1)

  const [sorts, setSorts] = useState<string[]>(["-submit_time"])
  const [filters, setFilters] = useState<TableFilter[]>([])

  const [applications, setApplications] = useState<
    PaginatedResponse<Users[]>["data"]
  >({
    data: [],
    nextPage: null,
    previousPage: null,
    total: 0,
  })

  const getData = async (page: number) => {
    setLoading(true)
    setPage(page)
    setApplications(prev => ({ ...prev, data: [] }))

    const evidenceUploadedFilters = (() => {
      const relevantFilters = filters.filter(x => x.id === "evidence_uploaded")
      if (relevantFilters.length > 1) {
        return null
      }

      return relevantFilters.map(x => x.value)
    })()

    const res = await api.applications.applications({
      params: {
        page,
        status: "previous",
        ordering: sorts,
        review_status: filters
          .filter(x => x.id === "review_status")
          .map(x => x.value),
        evidence_uploaded: evidenceUploadedFilters,
        ...(search && { search }),
      },
    })
    if (res.isSuccessful) {
      setApplications(res.data)
      // console.log(res.data)
    }
    setLoading(false)
  }

  useEffect(() => {
    getData(1)
  }, [search, filters, sorts])

  const portalEl = document.querySelector("#search-field-portal")

  return (
    <>
      {Boolean(portalEl) && createPortal(SearchField, portalEl!)}

      <TableWrapper
        content={(className, nameField) => (
          <Table
            className={className}
            data={applications.data}
            onSort={x => {
              if (isFirstRender) return
              setSorts(x)
            }}
            onFilter={x => {
              if (isFirstRender) return
              setFilters(x)
            }}
            headers={[
              {
                title: "Name",
                id: "full_name",
                sortable: true,
                align: "left",
                columnComponent: ({ row }) => (
                  <td>
                    <div className={clsx(nameField, "flex items-center")}>
                      <div className="shrink-0">
                        <Avatar
                          color="two"
                          name={`${row.applicationDetail?.firstName} ${row.applicationDetail?.lastName}`}
                          src={row.applicationDetail?.photo}
                        />
                      </div>
                      <div className="ml-1">
                        <Typography variant="strong">
                          {row.applicationDetail?.firstName}{" "}
                          {row.applicationDetail?.lastName}
                        </Typography>
                        <Typography variant="smallBody">
                          {row.applicationDetail?.email}
                        </Typography>
                      </div>
                    </div>
                  </td>
                ),
              },
              {
                title: "Application Date",
                id: "submit_time",
                align: "left",
                sortable: true,
                columnComponent: ({ row }) => (
                  <td>
                    {row.submitTime && (
                      <Typography variant="body">
                        {format(new Date(row.submitTime), "d MMMM yyyy")}
                      </Typography>
                    )}
                  </td>
                ),
              },
              {
                title: "Status",
                id: "review_status",
                filters: [
                  { displayName: "Accepted", value: "accepted" },
                  { displayName: "Rejected", value: "rejected" },
                  {
                    displayName: "Accepted via interview",
                    value: "accepted_via_interview",
                  },
                  {
                    displayName: "Rejected via interview",
                    value: "rejected_via_interview",
                  },
                ],
                columnComponent: ({ row }) => {
                  const tag = getApplicationTag({
                    applicationStatus: row.status,
                    interviewStatus: row.interview?.status || null,
                    reviewStatus: row.review.status,
                  })
                  return (
                    <td>{tag && <Tag color={tag.color} label={tag.text} />}</td>
                  )
                },
              },
              {
                title: "Documents Uploaded",
                id: "evidence_uploaded",
                filters: [
                  {
                    displayName: "Uploaded",
                    value: "true",
                  },
                  {
                    displayName: "Not Uploaded",
                    value: "false",
                  },
                ],
                columnComponent: ({ row }) => {
                  return <td>{row.evidenceUploaded ? <Check /> : "-"}</td>
                },
              },
              {
                title: "",
                id: "action",
                columnComponent: ({ row }) => (
                  <td>
                    <Button
                      component={Link}
                      to={`/dashboard/view/${row.id}`}
                      variant="text"
                    >
                      View
                    </Button>
                  </td>
                ),
              },
            ]}
            loading={loading}
          />
        )}
      />

      {applications.data.length === 0 && !loading && (
        <NoDataCard message="No data available" />
      )}

      {applications.total > 10 && (
        <Pagination
          className="mb-2"
          page={page}
          total={applications.total}
          onChange={getData}
        />
      )}
    </>
  )
}

export default PreviousApplications
