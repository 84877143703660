import { InputLabel, InputLabelProps } from "@suraasa/placebo-ui"
import clsx from "clsx"
import React from "react"
import { createUseStyles } from "react-jss"

const useStyles = createUseStyles(theme => ({
  customInputLabel: {
    "& .Typography-root": {
      ...theme.typography.strong,
      textTransform: "initial",
    },
  },
}))

const CustomInputLabel = ({
  className,
  children,
  label,
  ...props
}: InputLabelProps) => {
  const classes = useStyles()

  return (
    <InputLabel
      {...props}
      className={clsx(classes.customInputLabel, className)}
      label={typeof children === "string" ? children : label}
    />
  )
}

export default CustomInputLabel
