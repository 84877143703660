export const SLUGS = {
  step2: {
    slug: "step2",
    permanentSameAsCurrent: {
      slug: "permanentSameAsCurrent",
      options: {
        yes: 1,
        no: 2,
      },
    },
    referred: {
      slug: "have-you-been-referred-by-a-friendcolleaguefamil-step2",
      options: {
        yes: "Yes",
        no: "No",
      },
    },
    referralCodeOrEmail: {
      slug: "do-you-have-a-referral-code-or-the-email-address-o-step2",
    },
    referralMode: {
      slug: "referralMode",
      options: {
        code: "Referral Code",
        email: "Email Address",
      },
    },
    currentCountryOfResidence: {
      slug: "do-you-wish-to-take-suraasa-qualification-in-count-step2",
    },
  },
  step3: {
    slug: "step3",
  },
  step4: {
    slug: "step4",
  },
  step5: {
    slug: "step5",
  },
  step6: {
    slug: "step6",
    disability: {
      options: {
        yes: 11,
      },
    },
    healthProblems: {
      options: {
        yes: 13,
      },
    },
    arrested: {
      options: {
        yes: 15,
      },
    },
    suspended: {
      options: {
        yes: 17,
      },
    },
  },
  step7: {
    slug: "step7",
  },
  step8: {
    slug: "step8",
    learnedAboutProgramme: {
      options: {
        other: 26,
      },
    },
  },
  step9: {
    slug: "step9",
  },
} as const
