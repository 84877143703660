import { Typography } from "@suraasa/placebo-ui"
import { Question as QuestionType } from "api/resources/applications/types"
import Card from "components/shared/Card"
import React, { useContext } from "react"
import { createUseStyles } from "react-jss"
import { SLUGS } from "utils/applicationForm"
import { ApplicationContext } from "views/application/context/ApplicationContext"

import Question from "./Question"

const useStyles = createUseStyles({
  answer: {
    width: "75%",
    whiteSpace: "pre-wrap",
  },
})

const DisabilityFitnessCard = () => {
  const classes = useStyles()
  const { questionList } = useContext(ApplicationContext)

  const dynamicQuestions = questionList.filter(
    ({ question: { category } }) => category === SLUGS.step6.slug
  )

  const getOption = (data: QuestionType) => {
    const res = data.options.find(val =>
      data.responses.some(resVal => resVal.option === val.id)
    )
    if (res) {
      return res.text
    }
    return "Don’t want to disclose"
  }

  const getResponse = (data: QuestionType) => {
    const res = data.responses.find(val =>
      data.options.some(resVal => resVal.id === val.option)
    )

    return res || null
  }

  return (
    <Card className="mt-4 p-2">
      <Typography variant="title3">Disability and Fitness</Typography>
      <div className="flex flex-col gap-4">
        {dynamicQuestions.map(({ question, questionNumber }) => {
          const option = getOption(question)
          const res = getResponse(question)

          return (
            <div key={questionNumber}>
              <div className="flex flex-col justify-between gap-1 sm:flex-row">
                <Question data={question} hideResponse />
                <Typography color="primary.500" variant="strong">
                  {option}
                </Typography>
              </div>
              <Typography className={classes.answer} variant="smallBody">
                {res && res.additionalAnswer}
              </Typography>
            </div>
          )
        })}
      </div>
    </Card>
  )
}

export default DisabilityFitnessCard
