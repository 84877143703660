import { Checkbox, Radio, Typography } from "@suraasa/placebo-ui"
import {
  Option,
  Question as QuestionType,
  QuestionType as QuestionTypeType,
} from "api/resources/applications/types"
import clsx from "clsx"
import React from "react"

import InputLabel from "./InputLabel"

type Props = {
  data: QuestionType
  vertical?: boolean
  className?: string
  hideResponse?: boolean
  description?: string
}

const Question = ({
  data,
  vertical = false,
  className: userClassName,
  hideResponse = false,
  description = "",
}: Props) => {
  const getOptions = () => {
    switch (data.questionType) {
      case QuestionTypeType.SINGLE_SELECT: {
        return (
          <>
            <div
              className={
                vertical === false
                  ? "my-1 flex gap-5"
                  : "my-1 flex flex-col gap-1"
              }
            >
              {data?.options
                .sort((a, b) => a.order - b.order)
                .map((option, index) => (
                  <Radio
                    checked={Boolean(
                      data.responses.find(c => c.option === option.id)
                    )}
                    key={index}
                    label={option.text}
                  />
                ))}
            </div>
          </>
        )
      }
      case QuestionTypeType.MULTIPLE_SELECT: {
        const isCheckMultiSelect = (option: Option) =>
          data.responses.some(r => r.option === option.id)

        return (
          <div
            className={clsx("my-1 flex", {
              "gap-3 sm:gap-1.5 flex-col": vertical,
              "gap-5": !vertical,
            })}
          >
            {data.options
              .sort((a, b) => a.order - b.order)
              .map((option, index) => (
                <Checkbox
                  key={index}
                  checked={isCheckMultiSelect(option)}
                  label={option.text}
                  className="pointer-events-none"
                  value={option.id}
                />
              ))}
          </div>
        )
      }
      default: {
        return null
      }
    }
  }

  return (
    <div className={clsx(userClassName)}>
      <InputLabel label={data.text} required={data.isRequired} />
      {description && (
        <Typography className="mb-2" color="onSurface.500" variant="smallBody">
          {description}
        </Typography>
      )}
      {!hideResponse && getOptions()}
    </div>
  )
}

export default Question
