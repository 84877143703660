import { validateUrls } from "api/types"
import { getServiceURL } from "api/utils"
import { ServicePrefix } from "utils/constants"

const getSirius = getServiceURL(ServicePrefix.sirius)

export const urls = validateUrls({
  applications: {
    list: () => getSirius("/v1/applications/all/"),
    retrieve: applicationId => getSirius(`/v1/applications/${applicationId}/`),
    retrieveProfile: applicationId =>
      getSirius(`/v1/profiles/${applicationId}/`),
  },
  review: {
    bulkCreate: reviewId => getSirius(`/v1/reviews/${reviewId}/changes/`),
    list: () => getSirius("/v1/reviews/changes/"),
    create: applicationId =>
      getSirius(`/v1/reviews/applications/${applicationId}/`),
    update: () => getSirius(`/v1/reviews/`),
  },
  interview: {
    update: () => getSirius(`/v1/reviews/`),
  },
  overview: { list: () => getSirius(`/v1/applications/overview/admin/`) },
})
