import axios from "api/axios"
import {
  APIResponse,
  Config,
  ConfigWithURLParams,
  PaginatedAPIResponse,
} from "api/types"
import {
  formatErrorResponse,
  formatPaginatedResponse,
  formatSuccessResponse,
} from "api/utils"

import {
  ApplicantDetails,
  CommentType,
  Overview,
  ProfileDataType,
  ReviewStatusType,
  Users,
} from "./types"
import { urls } from "./urls"

export default {
  applications: async ({
    params,
  }: Config): Promise<PaginatedAPIResponse<Users[]>> => {
    try {
      const res = await axios.get(urls.applications.list(), { params })
      return formatPaginatedResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
  retrieve: async ({
    urlParams: { applicationId },
    params,
  }: ConfigWithURLParams): Promise<APIResponse<ApplicantDetails>> => {
    try {
      const res = await axios.get(urls.applications.retrieve(applicationId), {
        params,
      })
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
  retrieveProfile: async ({
    urlParams: { applicationId },
    params,
  }: ConfigWithURLParams): Promise<APIResponse<ProfileDataType>> => {
    try {
      const res = await axios.get(
        urls.applications.retrieveProfile(applicationId),
        {
          params,
        }
      )
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
  review: {
    list: async ({ params }: Config): Promise<APIResponse<CommentType[]>> => {
      try {
        const res = await axios.get(urls.review.list(), { params })
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    bulkCreate: async ({
      urlParams: { reviewId },
      data,
    }: ConfigWithURLParams): Promise<APIResponse<CommentType[]>> => {
      try {
        const res = await axios.post(urls.review.bulkCreate(reviewId), data)
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    getOrCreate: async ({
      urlParams: { applicationId },
      data,
    }: ConfigWithURLParams): Promise<APIResponse<ReviewStatusType>> => {
      try {
        const res = await axios.post(urls.review.create(applicationId), data)
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    updateStatus: async ({ params, data }: Config) => {
      try {
        const res = await axios.put(urls.review.update(), data, {
          params,
        })
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
  },
  interview: {
    update: async ({ params, data }: Config) => {
      try {
        const res = await axios.put(urls.interview.update(), data, {
          params,
        })
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
  },
  overview: async (): Promise<APIResponse<Overview>> => {
    try {
      const res = await axios.get(urls.overview.list())
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
}
