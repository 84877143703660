import {
  InputLabel,
  Radio,
  Select,
  TextField,
  Typography,
} from "@suraasa/placebo-ui"
import Question from "components/application/Question"
import Card from "components/shared/Card"
import React, { useContext } from "react"
import { GenderOptions, titleChoices } from "utils/constants"
import { calculateAge } from "utils/helpers"
import { ApplicationContext } from "views/application/masters-in-education/context/MEdContext"

import { SLUGS } from "./utils"

const PersonalDetails = () => {
  const {
    applicantData: data,
    questionList,
    countries,
  } = useContext(ApplicationContext)
  const {
    photo,
    title,
    firstName,
    lastName,
    dateOfBirth,
    gender,
    email,
    nationalityId,
  } = data

  const { female, male, preferNotToDisclose } = GenderOptions

  const isOtherGender = ![female, male, preferNotToDisclose].some(
    g => g === gender
  )
  const step2Questions = questionList.filter(
    ({ question: { category } }) => category === SLUGS.step2.slug
  )
  const step3Questions = questionList.filter(
    ({ question: { category } }) => category === SLUGS.step3.slug
  )

  const hispanicOrLatinoQuestion = step3Questions.find(
    q => q.question.slug === SLUGS.step3.hispanicOrLatino.slug
  )?.question
  const ethnicityQuestion = step3Questions.find(
    q => q.question.slug === SLUGS.step3.ethnicity.slug
  )?.question
  const otherEthnicity =
    ethnicityQuestion &&
    ethnicityQuestion.responses.filter(r => r.additionalAnswer)[0]

  const referredQuestion = step2Questions.find(
    q => q.question.slug === SLUGS.step2.referred.slug
  )?.question

  const referralCode =
    referredQuestion &&
    referredQuestion.responses.filter(r => r.additionalAnswer)[0]

  const referredByQuestion = step2Questions.find(
    q => q.question.slug === SLUGS.step2.referredBy.slug
  )?.question

  const referredByOther =
    referredByQuestion &&
    referredByQuestion.responses.filter(r => r.additionalAnswer)[0]

  return (
    <>
      <Card className="p-2 md:px-3">
        <Typography className="mt-2" variant="title3">
          Personal Details
        </Typography>
        <div className="flex flex-col gap-3">
          <div>
            <Typography variant="strong">Passport Size Photograph</Typography>

            <div className={"mt-1 h-[180px] w-[172px] bg-onSurface-50"}>
              <img
                alt="passport"
                className={"size-full rounded object-contain"}
                src={photo}
              />
            </div>
          </div>
          <div>
            <InputLabel label="Name" required />
            <div className="my-1 flex flex-col gap-2 md:flex-row">
              <div className="md:w-[130px]">
                <Select
                  className={"w-full"}
                  fullWidth={true}
                  label="title"
                  menuIsOpen={false}
                  options={titleChoices}
                  placeholder=" "
                  value={
                    title ? titleChoices.find(c => c.value === title) : null
                  }
                />
              </div>
              <TextField
                className="flex flex-1"
                fullWidth={true}
                label="First Name"
                value={firstName}
                readOnly
              />
              <TextField
                className="flex flex-1"
                fullWidth={true}
                label="Last Name"
                value={lastName}
                readOnly
              />
            </div>
          </div>
          <div className="flex items-center gap-2">
            <div className="w-full">
              <InputLabel label="Date of Birth" required />
              <TextField
                fullWidth={true}
                type="date"
                value={dateOfBirth || ""}
                readOnly
              />
            </div>
            {dateOfBirth && (
              <div className="w-full">
                <InputLabel label="Age" />
                <TextField
                  fullWidth={true}
                  value={calculateAge(dateOfBirth) || ""}
                  readOnly
                />
              </div>
            )}
          </div>
          <div>
            <InputLabel label="Gender" required />
            <div className="my-1 flex flex-col gap-2">
              <Radio
                checked={gender === GenderOptions.male}
                label="Male"
                readOnly
              />
              <Radio
                checked={gender === GenderOptions.female}
                label="Female"
                readOnly
              />
              <Radio
                checked={gender === GenderOptions.preferNotToDisclose}
                label="Prefer Not to Disclose"
                readOnly
              />
              <Radio checked={isOtherGender} label="Others" readOnly />
              {isOtherGender && (
                <>
                  <TextField
                    className="md:w-19"
                    fullWidth={false}
                    value={gender}
                    readOnly
                  />
                </>
              )}
            </div>
          </div>
          {hispanicOrLatinoQuestion && (
            <div>
              <Question data={hispanicOrLatinoQuestion} vertical />
            </div>
          )}
          {ethnicityQuestion && (
            <div>
              <Question data={ethnicityQuestion} vertical />
              {otherEthnicity && (
                <TextField
                  className="md:w-19"
                  fullWidth={false}
                  value={otherEthnicity.additionalAnswer}
                  readOnly
                />
              )}
            </div>
          )}
          <div>
            <InputLabel label="Nationality" required />
            <Select
              getOptionLabel={({ name }) => name}
              getOptionValue={({ uuid }) => uuid}
              options={countries}
              value={
                nationalityId
                  ? countries.find(c => c.uuid === nationalityId)
                  : null
              }
              menuIsOpen={false}
            />
          </div>
          <div className="w-full md:max-w-[500px]">
            <InputLabel label="Email" required />
            <TextField fullWidth={true} value={email} readOnly />
          </div>
          {referredQuestion && (
            <div>
              <Question data={referredQuestion} />
            </div>
          )}
          {referredByQuestion && (
            <div>
              <Question data={referredByQuestion} vertical />
              {referredByOther && (
                <div>
                  <InputLabel label="Please specify referrer." />
                  <TextField
                    type="text"
                    value={referredByOther.additionalAnswer}
                    readOnly
                  />
                </div>
              )}
            </div>
          )}
          {referralCode && (
            <div>
              <InputLabel label="Please enter the referral code" />
              <TextField
                type="text"
                value={referralCode.additionalAnswer}
                readOnly
              />
            </div>
          )}
        </div>
      </Card>
    </>
  )
}

export default PersonalDetails
