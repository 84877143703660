import { QualificationFormat } from "api/resources/applications/types"
import { EmploymentType, ModeOfLearning } from "api/resources/global/types"

export const AUTHORIZATION_HEADER_PREFIX = "Bearer"

export const BROWSER_STORAGE_KEYS = {
  auth: "auth",
}
export const USER_TYPE = "Application Form Admin"

export enum ServicePrefix {
  solis = "solis",
  gravity = "gravity",
  nebula = "nebula",
  kepler = "kepler",
  doppler = "doppler",
  analytics = "analytics",
  sirius = "sirius",
}

export enum Platforms {
  student = "Student",
}

export const Title = {
  Mr: 1,
  Ms: 2,
  Mrs: 3,
  Dr: 4,
  PreferNotToSay: 5,
} as const

export const titleChoices = [
  {
    label: "Mr.",
    value: Title.Mr,
  },
  {
    label: "Ms.",
    value: Title.Ms,
  },
  {
    label: "Mrs.",
    value: Title.Mrs,
  },
  {
    label: "Dr.",
    value: Title.Dr,
  },
  {
    label: "Prefer not to say",
    value: Title.PreferNotToSay,
  },
]

export const GenderOptions = {
  male: "Male",
  female: "Female",
  others: "Others",
  preferNotToDisclose: "Prefer not to disclose",
} as const

export const employmentTypeOptions = [
  { label: "Full Time", value: EmploymentType.FULL_TIME },
  { label: "Part time", value: EmploymentType.PART_TIME },
  { label: "Fresher", value: EmploymentType.FRESHER },
  { label: "Intern", value: EmploymentType.INTERN },
  { label: "Freelancer", value: EmploymentType.FREELANCE },
  { label: "Self-Employed", value: EmploymentType.SELF_EMPLOYED },
]
export const modeOfLearningTypeOptions = [
  { label: "Online", value: ModeOfLearning.ONLINE },
  { label: "Offline", value: ModeOfLearning.OFFLINE },
  { label: "Blended", value: ModeOfLearning.BLENDED },
  { label: "Default", value: ModeOfLearning.DEFAULT },
]
export const qualificationFormatChoices = [
  { label: "Regular", value: QualificationFormat.REGULAR },
  { label: "Distance", value: QualificationFormat.DISTANCE },
]

export const applicationFormSlugs = {
  pgctl: "india-pgctl-application",
  med: "masters-in-education",
} as const
