import { Typography } from "@suraasa/placebo-ui"
import { Attachment } from "iconoir-react"
import React from "react"
import { Link } from "react-router-dom"

type Props = {
  link: string | undefined | null
  attachmentName: string | null
}

const AttachmentItem = ({ attachmentName, link }: Props) => {
  return (
    <Link to={link || ""} target="_blank">
      <div className="flex items-center justify-between rounded-lg bg-onSurface-100 p-1 md:w-[250px]">
        <div className="flex w-[calc(100%-theme(width.1))] items-center">
          <Attachment className="mr-1.5 size-2.5" />
          <Typography className="w-full truncate" variant="strong">
            {attachmentName}
          </Typography>
        </div>
      </div>
    </Link>
  )
}

export default AttachmentItem
