import { Tag, Typography } from "@suraasa/placebo-ui"
import {
  CheckListType,
  ReviewChangeItemType,
  WorkExperience,
} from "api/resources/applications/types"
import Card from "components/shared/Card"
import * as localForage from "localforage"
import omit from "lodash/omit"
import React, { useContext, useEffect, useState } from "react"
import { employmentTypeOptions } from "utils/constants"
import { formatDate } from "utils/helpers"
import {
  ApplicationContext,
  GeneralCommentSections,
} from "views/application/context/ApplicationContext"

import AuditField from "./AuditField"
import GeneralComments, { AddCommentsButton } from "./GeneralComments"

const EmploymentCard = () => {
  const {
    workExperiences,
    updateGeneralComment,
    removeGeneralComment,
    getGeneralCommentByType,
    isReviewDisabled,
    reviewList,
    employmentCheckList,
    setEmploymentCheckList,
    isSalesPerson,
    applicationId,
  } = useContext(ApplicationContext)
  const [showGeneralComment, setShowGeneralComment] = useState(false)

  const getSelectedEmploymentType = (data: WorkExperience) => {
    const empType = employmentTypeOptions.filter(
      a => a.value === data.employmentType && a
    )[0]

    return empType.label || ""
  }
  const onCommentChange = (comment: string) => {
    if (comment.length === 0) {
      removeGeneralComment(GeneralCommentSections.EMPLOYMENT)
    } else {
      updateGeneralComment(GeneralCommentSections.EMPLOYMENT, {
        itemId: null,
        itemType: ReviewChangeItemType.workexperience,
        comment: comment,
      })
    }
  }

  const updatedComment = getGeneralCommentByType(
    GeneralCommentSections.EMPLOYMENT
  )
  const generalComment =
    reviewList.find(
      v =>
        v.itemType === ReviewChangeItemType.workexperience && v.itemId === null
    )?.comment || ""

  const handleOnChange = (entry: string | null, itemId: number) => {
    const fieldNameObj = employmentCheckList.find(i => i.itemId === itemId)
    if (fieldNameObj && typeof entry === "string" && entry.length === 0) {
      setEmploymentCheckList(prevEvents =>
        prevEvents.filter(item => {
          if (item.itemId === itemId) return false

          return true
        })
      )
      return
    }
    if (employmentCheckList.find(i => i.itemId === itemId)) {
      if (entry) {
        setEmploymentCheckList(prevState =>
          prevState.map(i =>
            i.itemId === itemId ? omit({ ...i, comment: entry }, "error") : i
          )
        )
      }
    } else {
      setEmploymentCheckList(prevState => [
        ...prevState,
        {
          itemId,
          itemType: ReviewChangeItemType.workexperience,
          comment: entry,
        },
      ])
    }
  }
  useEffect(() => {
    localForage
      .getItem<CheckListType>(applicationId)
      .then(item => {
        if (item && item.employment) {
          setEmploymentCheckList(item.employment)
        }
      })
      .catch(err => {
        console.error(err)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!isReviewDisabled) {
      if (employmentCheckList.length > 0) {
        localForage
          .getItem<CheckListType>(applicationId)
          .then(item => {
            localForage.setItem(applicationId, {
              ...item,
              employment: employmentCheckList,
            })
          })
          .catch(err => {
            console.error(err)
          })
      } else {
        localForage
          .getItem<CheckListType>(applicationId)
          .then(item => {
            if (item && item.employment) {
              delete item.employment
              localForage.setItem(applicationId, item)
            }
          })
          .catch(err => {
            console.error(err)
          })
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employmentCheckList, isReviewDisabled])

  return (
    <Card className="mt-4">
      <div className="gap-3 px-2 pt-2">
        <div className="flex justify-between">
          <Typography variant="title3">Employment Information</Typography>
          <div>
            <AddCommentsButton
              show={showGeneralComment}
              disabled={isReviewDisabled}
              onClick={() => setShowGeneralComment(!showGeneralComment)}
            />
          </div>
        </div>
        <div className="mt-2 flex flex-col gap-2">
          {workExperiences.length === 0 && (
            <Typography className="mb-2" textAlign="center" variant="strong">
              No Data Available
            </Typography>
          )}
          {workExperiences.map(item => (
            <div key={item.id}>
              <AuditField
                disabled={isReviewDisabled}
                error={
                  employmentCheckList.find(i => i.itemId === item.id)?.error ||
                  ""
                }
                hideCheckBox={isSalesPerson}
                key={item.id}
                slug={item.id.toString()}
                text={
                  employmentCheckList.find(i => i.itemId === item.id)?.comment
                }
                onChange={entry => handleOnChange(entry, item.id)}
              >
                <div className="flex">
                  <Typography variant="strong">{item.title}</Typography>
                  {item.suraasaId && (
                    <Tag
                      className="pl-1"
                      color="onSurface"
                      label="From Profile"
                      size="sm"
                    />
                  )}
                </div>
                <Typography variant="body">
                  {item.organisationName}
                  <span className="mx-1">&#8226;</span>
                  {getSelectedEmploymentType(item)}
                </Typography>
                <Typography color="onSurface.500" variant="smallBody">
                  {item.state?.name}, {item.country?.name}
                </Typography>
                <Typography color="onSurface.500" variant="smallBody">
                  {formatDate(item.startDate, "MMMM yyyy")}
                  <span className="mx-1">-</span>
                  {item.endDate
                    ? formatDate(item.endDate, "MMMM yyyy")
                    : "Present"}
                </Typography>
              </AuditField>
            </div>
          ))}
        </div>
      </div>
      <GeneralComments
        showCommentBox={generalComment ? true : showGeneralComment}
        placeholder="Add Comments for Employment Information"
        onCommentChange={onCommentChange}
        comment={updatedComment || generalComment}
        disabled={!!generalComment}
      />
      {!showGeneralComment && !generalComment && <div className="pb-2" />}
    </Card>
  )
}

export default EmploymentCard
