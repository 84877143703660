import { Typography } from "@suraasa/placebo-ui"
import Question from "components/application/Question"
import Card from "components/shared/Card"
import { useContext } from "react"
import { ApplicationContext } from "views/application/masters-in-education/context/MEdContext"

import AttachmentItem from "./Attachment"
import { findOptionFromResponse, SLUGS } from "./utils"

const ScholarshipDetails = () => {
  const { questionList } = useContext(ApplicationContext)

  const dynamicQuestions = questionList.filter(
    ({ question: { category } }) => category === SLUGS.step8.slug
  )
  const applyForScholarship = dynamicQuestions.find(
    q => q.question.slug === SLUGS.step8.applyForScholarship.slug
  )
  const existingScholarshipQuestion = dynamicQuestions.find(
    q => q.question.slug === SLUGS.step8.existingScholarship.slug
  )?.question
  const uploadDocumentsQuestion = dynamicQuestions.find(
    q => q.question.slug === SLUGS.step8.uploadDocuments.slug
  )?.question

  const hasExistingScholarship =
    existingScholarshipQuestion &&
    findOptionFromResponse(
      existingScholarshipQuestion.options,
      existingScholarshipQuestion.responses[0]
    )?.text === SLUGS.step8.existingScholarship.options.yes

  const documentResponses = uploadDocumentsQuestion?.responses || []

  return (
    <Card className="mt-4 p-2">
      <Typography variant="title3">Scholarship</Typography>
      <div className="flex flex-col gap-3">
        {applyForScholarship && (
          <div>
            <Question
              data={applyForScholarship.question}
              description={SLUGS.step8.applyForScholarship.description}
            />
          </div>
        )}
        {existingScholarshipQuestion && (
          <div>
            <Question
              data={existingScholarshipQuestion}
              description={SLUGS.step8.existingScholarship.description}
            />
          </div>
        )}
        {hasExistingScholarship && uploadDocumentsQuestion && (
          <div>
            <Question data={uploadDocumentsQuestion} vertical />
            {documentResponses.length > 0 && (
              <div className="mt-4 space-y-2">
                <Typography variant="strong">Upload Letter</Typography>
                <div className="mt-2 space-y-2">
                  {documentResponses.map((r, i) => (
                    <div className="flex flex-col gap-1" key={i}>
                      <Typography variant="preTitle">
                        {uploadDocumentsQuestion &&
                          findOptionFromResponse(
                            uploadDocumentsQuestion.options,
                            r
                          )?.text}
                      </Typography>
                      <AttachmentItem
                        attachmentName={"Click here to view"}
                        link={r.additionalAnswerFile}
                      />
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </Card>
  )
}

export default ScholarshipDetails
