import { AuthData } from "api/resources/users/types"
import { transformKeys } from "api/utils"
import camelCase from "lodash/camelCase"
import { BROWSER_STORAGE_KEYS, Platforms } from "utils/constants"

import { LocalStorage } from "./storageFactory"

export function saveAuthInfo(authInfo: AuthData) {
  const newAuthInfoObj: AuthData = transformKeys(authInfo, camelCase)
  LocalStorage.setItem(
    BROWSER_STORAGE_KEYS.auth,
    JSON.stringify(newAuthInfoObj)
  )
}

export function getAuthInfo() {
  const authInfo = LocalStorage.getItem("auth")

  if (authInfo !== null) {
    const newAuthInfoObj: AuthData = transformKeys(
      JSON.parse(authInfo),
      camelCase
    )
    return newAuthInfoObj
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return authInfo
}

export function clearAuthInfo() {
  const { auth } = BROWSER_STORAGE_KEYS
  LocalStorage.removeItem(auth)
  sessionStorage.removeItem(auth)
}

export const isValidPlatform = (
  platform: string | null,
  validPlatformList = Object.values(Platforms)
): platform is Platforms =>
  platform ? validPlatformList.includes(platform as Platforms) : false

export const getPlatform = (
  platform: string | null | undefined,
  validPlatformList?: Platforms[]
) => {
  if (!platform) return null

  return isValidPlatform(platform, validPlatformList) ? platform : null
}
