import api from "api"
import {
  ApplicantDetails,
  Certification,
  LanguageProficiency,
  PersonalDetails,
  Qualification,
  Reference,
  ReviewStatusType,
  WorkExperience,
} from "api/resources/applications/types"
import { Country, LanguageProficiencyChoices } from "api/resources/global/types"
import { createContext, useEffect, useMemo, useState } from "react"

export type ApplicationContextType = {
  applicationId: string
  applicantData: PersonalDetails
  questionList: ApplicantDetails["questionnaireQuestions"]
  languageProficiencies: LanguageProficiency[]
  qualifications: Qualification[]
  certifications: Certification[]
  workExperiences: WorkExperience[]
  references: Reference[]
  countries: Country[]
  review: ReviewStatusType | null
  toggleLoading: (state: boolean) => void
  isSalesPerson?: boolean
  submitTime: string | null | undefined
}

export const proficiencyChoices = [
  {
    label: "Elementary",
    value: LanguageProficiencyChoices.ELEMENTARY,
  },
  {
    label: "Limited Working",
    value: LanguageProficiencyChoices.LIMITED_WORKING,
  },
  {
    label: "Professional Working",
    value: LanguageProficiencyChoices.PROFESSIONAL_WORKING,
  },
  {
    label: "Full Professional",
    value: LanguageProficiencyChoices.FULL_PROFESSIONAL,
  },
  {
    label: "Native/Bi-lingual",
    value: LanguageProficiencyChoices.NATIVE,
  },
]

export const ApplicationContext = createContext<ApplicationContextType>({
  review: null,

  applicationId: "",

  applicantData: {
    id: 0,
    title: null,
    firstName: "",
    lastName: "",
    email: "",
    dateOfBirth: null,
    gender: undefined,
    nationalIdNumber: null,
    nationalityId: null,
    mobilePhoneNumber: null,
    photo: undefined,
    passportNumber: null,
    whatsappPhoneNumber: null,
    homePhoneNumber: null,
    workPhoneNumber: null,
    currentResidence: undefined,
    permanentResidence: undefined,
    permanentSameAsCurrent: 0,
    addresses: [],
  },
  questionList: [],
  languageProficiencies: [],
  qualifications: [],
  certifications: [],
  workExperiences: [],
  references: [],
  countries: [],
  toggleLoading: () => {},
  isSalesPerson: false,
  submitTime: null,
})

export const ApplicationContextProvider = ({
  toggleLoading,
  applicationId,
  children,
  isSalesPerson,
}: {
  toggleLoading: (state: boolean) => void
  applicationId: string
  isSalesPerson?: boolean
  children: React.ReactNode
}) => {
  const [submitTime, setSubmitTime] = useState<string | undefined | null>(null)
  const [applicantData, setApplicantData] = useState<PersonalDetails>({
    id: 0,
    title: null,
    firstName: "",
    lastName: "",
    email: "",
    dateOfBirth: null,
    gender: undefined,
    nationalIdNumber: null,
    nationalityId: null,
    mobilePhoneNumber: null,
    photo: undefined,
    passportNumber: null,
    whatsappPhoneNumber: null,
    homePhoneNumber: null,
    workPhoneNumber: null,
    currentResidence: undefined,
    permanentResidence: undefined,
    permanentSameAsCurrent: 0,
    addresses: [],
  })
  const [questionList, setQuestionList] = useState<
    ApplicantDetails["questionnaireQuestions"]
  >([])
  const [languageProficiencies, setLanguageProficiencies] = useState<
    LanguageProficiency[]
  >([])
  const [qualifications, setQualifications] = useState<Qualification[]>([])
  const [certifications, setCertifications] = useState<Certification[]>([])
  const [workExperiences, setWorkExperiences] = useState<WorkExperience[]>([])
  const [references, setReferences] = useState<Reference[]>([])

  const [review, setReview] = useState<ReviewStatusType | null>(null)

  useEffect(() => {
    const getReview = async () => {
      const res = await api.applications.review.getOrCreate({
        urlParams: {
          applicationId,
        },
      })
      if (res.isSuccessful) {
        setReview(res.data)
      }
    }
    getReview()
  }, [applicationId])

  const [countries, setCountries] = useState<Country[]>([])

  const setPersonalDetailsData = (data: ApplicantDetails) => {
    setApplicantData({
      ...data.applicationDetail,
    })
    setSubmitTime(data.submitTime)
  }

  useEffect(() => {
    const getData = async () => {
      toggleLoading(true)
      const res = await api.applications.retrieve({
        urlParams: { applicationId },
      })
      if (res.isSuccessful) {
        setPersonalDetailsData(res.data)
        if (res.data.questionnaireQuestions) {
          setQuestionList(res.data.questionnaireQuestions)
        }
        setReferences(res.data.references)
      }
      toggleLoading(false)
    }
    const getProfileData = async () => {
      const res = await api.applications.retrieveProfile({
        urlParams: { applicationId },
        params: {
          fields: [
            "language_proficiencies",
            "qualifications",
            "certifications",
            "work_experiences",
          ],
        },
      })
      if (res.isSuccessful) {
        setLanguageProficiencies(res.data.languageProficiencies)
        setQualifications(res.data.qualifications)
        setCertifications(res.data.certifications)
        setWorkExperiences(res.data.workExperiences)
      }
      toggleLoading(false)
    }
    if (applicationId) {
      getData()
      getProfileData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicationId])

  useEffect(() => {
    const getData = async () => {
      const countriesRes = await api.global.listCountries({
        params: { page: "-1" },
      })

      if (countriesRes.isSuccessful) setCountries(countriesRes.data)
    }
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const contextValues = useMemo(
    () => ({
      applicationId,
      applicantData,
      questionList,
      languageProficiencies,
      qualifications,
      certifications,
      workExperiences,
      references,
      countries,
      review,
      toggleLoading,
      isSalesPerson,
      submitTime,
    }),
    [
      submitTime,
      applicationId,
      applicantData,
      questionList,
      languageProficiencies,
      qualifications,
      certifications,
      workExperiences,
      references,
      countries,
      review,
      toggleLoading,
      isSalesPerson,
    ]
  )
  return (
    <ApplicationContext.Provider value={contextValues}>
      {children}
    </ApplicationContext.Provider>
  )
}
