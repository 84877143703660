import clsx from "clsx"
import React, { FC } from "react"
import { createUseStyles } from "react-jss"

const useStyles = createUseStyles(() => ({
  container: {
    backgroundColor: "white",
    boxShadow: "1px 1px 5px rgba(0, 0, 0, 0.1)",
    borderRadius: "8px",
  },
}))

type Props = {
  children: React.ReactNode
  className?: string
}

const Card: FC<Props> = ({ children, className }) => {
  const classes = useStyles()
  return (
    <div className={clsx("flex flex-col gap-3 ", classes.container, className)}>
      {children}
    </div>
  )
}

export default Card
