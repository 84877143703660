import { Container, Divider, TextField } from "@suraasa/placebo-ui"
import api from "api"
import Navbar from "components/shared/Navbar/Navbar"
import Tabs from "components/shared/tabs/Tabs"
import useTabs, { Tabs as TabsType } from "components/shared/tabs/useTabs"
import React, { useEffect, useState } from "react"
import { createUseStyles } from "react-jss"
import { useSearchParams } from "react-router-dom"
import { ValueOf } from "types"
import { Search } from "utils/icons"

import InProgress from "./InProgress"
import NewApplications from "./NewApplications"
import PreviousApplications from "./PreviousApplications"

// type Props = {}
const useStyles = createUseStyles({
  searchInput: {
    marginTop: "-16px",
    "& input": {
      minHeight: "36px",
    },
  },
  searchAnimationEnter: {
    opacity: 0,
  },
  searchAnimationEnterActive: {
    opacity: 1,
    transition: "opacity 500ms",
  },
  searchAnimationExit: {
    opacity: 1,
  },
  searchAnimationExitActive: {
    opacity: 0,
    transition: "opacity 250ms",
  },
  divider: {
    position: "relative",
  },
})

const TABS = {
  inProgress: "In Progress",
  previousApplications: "Previous Applications",
}

const getActiveTabKey = (tab: ValueOf<typeof TABS> | null) => {
  if (tab === "In Progress") {
    return "inProgress"
  }

  return "previousApplications"
}

const AdminDashboard = () => {
  const [tabs, setTabs] = useState<TabsType>([
    {
      name: "In Progress",
      content: InProgress,
    },
    {
      name: "New Applications",
      content: NewApplications,
    },
    {
      name: "Previous Applications",
      content: PreviousApplications,
    },
  ])
  const [activeTab, setActiveTab] = useTabs({ tabs, initialTab: tabs[1].name })
  const [searchParams] = useSearchParams()
  const classes = useStyles()

  const currentTab = searchParams.get("tab")
  // const isNewApplicationTab = currentTab === "New Applications"

  useEffect(() => {
    const getOverview = async () => {
      const res = await api.applications.overview()
      if (res.isSuccessful) {
        const newTabs: TabsType = [
          {
            name: "In Progress",
            content: InProgress,
            count: res.data.inProgressApplications,
          },
          {
            name: "New Applications",
            content: NewApplications,
            count: res.data.newApplications,
          },
          {
            name: "Previous Applications",
            content: PreviousApplications,
          },
        ]
        setTabs(newTabs)
      } else {
        console.error(res.errors)
      }
    }
    getOverview()
  }, [currentTab])

  return (
    <>
      <Navbar />
      <Container>
        <div className="flex justify-between">
          <Tabs activeTab={activeTab} tabs={tabs} onChange={setActiveTab} />

          <div id="search-field-portal" />
        </div>
        <Divider className={classes.divider} weight="light" />
      </Container>

      <Container className="mt-4">
        <activeTab.content search={""} />
      </Container>
    </>
  )
}

export default AdminDashboard
