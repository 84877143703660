import { Error, ErrorType } from "@suraasa/error-pages"
import Navbar from "components/shared/Navbar/Navbar"
import React from "react"
import { useNavigate } from "react-router"
import { routes } from "utils/routes"

const ErrorPage = ({ errorCode }: Pick<ErrorType, "errorCode">) => {
  const navigate = useNavigate()
  switch (errorCode) {
    case 401:
      return (
        <Error
          errorCode={401}
          navbar={<Navbar />}
          navigate={navigate}
          options={{ onLogin: () => navigate(routes.login) }}
        />
      )
    case 403:
      return (
        <Error
          errorCode={403}
          message="You do not have permission to view this page."
          navbar={<Navbar />}
          navigate={navigate}
          options={{
            onHomepage: () => navigate(routes.help),
            onHelp: () => navigate(routes.help),
          }}
        />
      )
    case 404:
      return (
        <Error
          errorCode={404}
          navbar={<Navbar />}
          navigate={navigate}
          options={{
            onHelp: () => navigate(routes.help),
            onHomepage: () => navigate(routes.dashboard),
          }}
        />
      )
    case 500:
      return (
        <Error
          errorCode={500}
          navbar={<Navbar />}
          navigate={navigate}
          options={{
            onHelp: () => navigate(routes.help),
          }}
        />
      )
    default:
      return null
  }
}

export default ErrorPage
