import { Typography } from "@suraasa/placebo-ui"
import clsx from "clsx"
import Card from "components/shared/Card"
import React, { useContext } from "react"
import { createUseStyles } from "react-jss"
import { SLUGS } from "utils/applicationForm"
import { ApplicationContext } from "views/application/context/ApplicationContext"

import Question from "./Question"

const useStyles = createUseStyles({
  answer: {
    width: "75%",
  },
})

const OthersCard = () => {
  const classes = useStyles()

  const { questionList } = useContext(ApplicationContext)

  const dynamicQuestions = questionList.filter(
    ({ question: { category } }) => category === SLUGS.step8.slug
  )

  return (
    <Card className="mt-4 p-2">
      <Typography variant="title3">Other Information</Typography>
      <div className="flex flex-col gap-4">
        {dynamicQuestions.map(({ question }) => {
          const selectedOption = question.options.find(val =>
            question.responses.some(resVal => resVal.option === val.id)
          )?.text
          const additionalAnswer = question.responses.find(val =>
            question.options.some(resVal => resVal.id === val.option)
          )?.additionalAnswer

          return (
            <div key={question.id}>
              <div>
                <div className="flex flex-col justify-between gap-1 sm:flex-row">
                  <Question data={question} hideResponse />
                  <Typography color="primary.500" variant="strong">
                    {selectedOption && selectedOption}
                  </Typography>
                </div>
                {additionalAnswer && (
                  <Typography
                    className={clsx(classes.answer, "mt-0.5")}
                    variant="smallBody"
                  >
                    {additionalAnswer}
                  </Typography>
                )}
              </div>
            </div>
          )
        })}
      </div>
    </Card>
  )
}

export default OthersCard
