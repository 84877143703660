import { CircularProgress } from "@suraasa/placebo-ui"
import { createUseStyles } from "react-jss"

const useStyles = createUseStyles(theme => ({
  progressOverlay: {
    position: "absolute",
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    left: 0,
    top: 0,
    zIndex: theme.zIndex.dialog,
    background: "rgba(0, 0, 0, 0.1)",
    borderRadius: theme.spacing(0.5),
  },
}))

const LoadingOverlay = () => {
  const classes = useStyles()
  return (
    <div className={classes.progressOverlay}>
      <CircularProgress />
    </div>
  )
}
export default LoadingOverlay
