import { Button, Container } from "@suraasa/placebo-ui"
import clsx from "clsx"
import { NavbarProps } from "components/shared/Navbar/types"
import React from "react"
import { createUseStyles } from "react-jss"
import { clearAuthInfo, getAuthInfo } from "utils/auth"
// import { LogOut } from "iconoir-react"
import { getPlatformURL } from "utils/helpers"

import NavLogo from "./NavLogo"
import { USER_TYPE } from "utils/constants"

const useStyles = createUseStyles(theme => ({
  root: {
    backgroundColor: theme.colors.common.white[500],
    maxHeight: "62px",
    height: "62px",
    borderBottom: `1px solid ${theme.colors.onSurface[200]}`,
  },

  containerRoot: {
    width: "100%",
  },

  container: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      flexWrap: "wrap",
    },
  },
}))

const AdminNavbar = ({
  className,
  gutterBottom = true,
  slotEnd,
  slotStart,
}: NavbarProps) => {
  const classes = useStyles()

  const authInfo = getAuthInfo()
  // const isLoggedIn = Boolean(authInfo)

  return (
    <>
      <div
        className={clsx({
          "mb-5": gutterBottom,
        })}
      >
        <nav
          className={clsx(classes.root, "flex items-center py-1", className)}
        >
          <Container className={classes.containerRoot}>
            <div className={clsx(classes.container)}>
              <NavLogo
                href={"/"}
                slotStart={slotStart}
              />

              {/* End */}
              <div className="flex items-center gap-1">{slotEnd}</div>
              {authInfo && (
                <Button
                  variant="text"
                  onClick={() => {
                    clearAuthInfo()
                    const url = new URL(getPlatformURL("sso", "logout"))
                    url.searchParams.append(
                      "origin",
                      `${window.location.origin}`
                    )
                    url.searchParams.append(
                      "platform",
                      USER_TYPE
                    )
                    window.location.href = url.href
                  }}
                >
                  Log Out
                </Button>
              )}
            </div>
          </Container>
        </nav>
      </div>
    </>
  )
}

export default AdminNavbar
