import { Typography } from "@suraasa/placebo-ui"
import { LanguageProficiency } from "api/resources/applications/types"
import Card from "components/shared/Card"
import { useContext } from "react"
import {
  ApplicationContext,
  proficiencyChoices,
} from "views/application/context/ApplicationContext"

import AcademicEvidence from "./AcademicEvidence"

const LanguageCard = () => {
  const { languageProficiencies } = useContext(ApplicationContext)

  const getSelectedProficiency = (data: LanguageProficiency) => {
    const selected = proficiencyChoices.filter(
      a => a.value === data.proficiency && a
    )[0]
    return selected?.label ?? ""
  }

  return (
    <Card className="mt-4 p-2">
      <Typography variant="title3">Language Proficiency</Typography>
      <div className="flex flex-col gap-2 pl-8">
        {languageProficiencies.map(item => (
          <div key={item.id}>
            <Typography variant="strong">{item.language.name}</Typography>
            {item.hasEvidence && (
              <Typography color="onSurface.800" variant="body">
                {item.certificateName && item.certificateName}{" "}
                <span>&#8226;</span>{" "}
                {item.certificateIssuerName && item.certificateIssuerName}
              </Typography>
            )}
            <Typography color="onSurface.800" variant="body">
              {item.proficiencyScore && `${item.proficiencyScore}%`}{" "}
              <span>&#8226;</span> {getSelectedProficiency(item)}
            </Typography>
            {item.hasEvidence && (
              <div className="mt-1">
                <AcademicEvidence
                  url={item.url || item.attachment}
                  name={item.attachmentName}
                />
              </div>
            )}
          </div>
        ))}
      </div>
    </Card>
  )
}

export default LanguageCard
