import { TextField } from "@suraasa/placebo-ui"
import { Search } from "iconoir-react"
import debounce from "lodash/debounce"
import React, { useState } from "react"

type Props = {
  className?: string
}

const useSearchField = (props: Props) => {
  const [search, setSearch] = useState("")

  const handleSearch = debounce(function (value: string) {
    setSearch(value)
  }, 750)

  const SearchField = (
    <TextField
      className={props.className}
      name="search"
      placeholder="Search by name or email"
      startIcon={<Search />}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
        handleSearch(e.target.value)
      }
    />
  )

  return { SearchField, searchText: search }
}

export default useSearchField
