import { Button } from "@suraasa/placebo-ui"
import api from "api"
import { CheckListType } from "api/resources/applications/types"
import * as localForage from "localforage"
import React, { useContext, useState } from "react"
import { useNavigate } from "react-router"
import toast from "utils/toast"
import { ApplicationContext } from "views/application/context/ApplicationContext"

const ERROR_MSG = "Required to be filled or uncheck the box"

const AskForChangesButton = ({
  size,
}: {
  size?: "sm" | "md" | "lg" | undefined
}) => {
  const navigate = useNavigate()
  const [submitLoading, setSubmitLoading] = useState(false)

  const {
    educationCheckList,
    setEducationCheckList,
    setPersonalCheckList,
    personalCheckList,
    applicationId,
    review,
    generalComments,
    employmentCheckList,
    setEmploymentCheckList,
  } = useContext(ApplicationContext)

  const onSubmitComment = () => {
    setSubmitLoading(true)
    let hasError = false

    educationCheckList.forEach(({ comment, error }) => {
      if (error || !comment) hasError = true
    })
    personalCheckList.forEach(({ comment, error }) => {
      if (error || !comment) hasError = true
    })
    employmentCheckList.forEach(({ comment, error }) => {
      if (error || !comment) hasError = true
    })

    if (hasError) {
      setEducationCheckList(prevState =>
        prevState.map(i =>
          i.error || !i.comment ? { ...i, error: ERROR_MSG } : i
        )
      )
      setPersonalCheckList(prevState =>
        prevState.map(i =>
          i.error || !i.comment ? { ...i, error: ERROR_MSG } : i
        )
      )
      setEmploymentCheckList(prevState =>
        prevState.map(i =>
          i.error || !i.comment ? { ...i, error: ERROR_MSG } : i
        )
      )
      setSubmitLoading(false)
      toast.error("Recheck all the comments")
      return
    }

    const onSubmit = async (item: CheckListType) => {
      const res = await api.applications.review.bulkCreate({
        urlParams: {
          reviewId: review?.id || "",
        },
        data: {
          reviewChangeData: [
            ...(item.education ? item.education : []),
            ...(item.personal ? item.personal : []),
            ...(item.employment ? item.employment : []),
            ...(generalComments.length > 0
              ? generalComments.map(v => v.comment)
              : []),
          ],
        },
      })

      if (res.isSuccessful) {
        localForage
          .removeItem(applicationId)
          .then(() => {
            setSubmitLoading(false)
            navigate(-1)
          })
          .catch(err => {
            toast.error(err)
            console.error(err)
            setSubmitLoading(false)
          })
      } else {
        toast.error(res.errors.detail)
        console.error(res.errors)
        setSubmitLoading(false)
      }
    }

    localForage
      .getItem<CheckListType>(applicationId)
      .then(item => {
        if (item) onSubmit(item)
        else if (generalComments.length > 0) {
          onSubmit({})
        } else {
          setSubmitLoading(false)
        }
      })
      .catch(err => {
        console.error(err)
        setSubmitLoading(false)
      })
  }
  return (
    <Button loading={submitLoading} size={size} onClick={onSubmitComment}>
      Ask For Changes
    </Button>
  )
}

export default AskForChangesButton
