import { Typography } from "@suraasa/placebo-ui"
import clsx from "clsx"
import React, { useContext } from "react"
import { createUseStyles } from "react-jss"
import { ApplicationContext } from "views/application/context/ApplicationContext"

import AskForChangesButton from "./AskForChangesButton"

const useStyles = createUseStyles(theme => ({
  askForChanges: {
    position: "sticky",
    width: "100%",
    background: theme.colors.common.white[500],
    border: `1px solid ${theme.colors.surface[200]}`,
    bottom: 24,
    marginTop: 24,
    boxShadow: "1px 1px 5px rgba(0, 0, 0, 0.1)",
    borderRadius: theme.spacing(0.5),
    zIndex: 2,
  },
}))

const FloatingBar = () => {
  const classes = useStyles()
  const {
    educationCheckList,
    personalCheckList,
    employmentCheckList,
    isReviewDisabled,
    generalComments,
  } = useContext(ApplicationContext)

  if (isReviewDisabled) return null

  return (
    <>
      {(educationCheckList.length > 0 ||
        personalCheckList.length > 0 ||
        employmentCheckList.length > 0 ||
        generalComments.length > 0) && (
        <div
          className={clsx(
            "mx-auto flex items-center justify-between p-2",
            classes.askForChanges
          )}
        >
          <Typography variant="body">
            Looks like you have marked some fields to be changed.
          </Typography>

          <AskForChangesButton />
        </div>
      )}
    </>
  )
}

export default FloatingBar
