import { getServiceURL } from "api/utils"
import { ServicePrefix } from "utils/constants"

const getSolisURL = getServiceURL(ServicePrefix.solis)

export const urls = {
  refreshToken: () => getSolisURL("/v1/auth/refresh-token/"),
  generateAuthCode: () => getSolisURL("/v1/auth/codes/"),
  generatePlatformToken: () => getSolisURL("/v1/auth/user-tokens/"),
  listUserActions: () => getSolisURL(`/v1/auth/actions/`),
}
