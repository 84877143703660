import {
  Button,
  Chip,
  Container,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogTitle,
  TextField,
  Tooltip,
  Typography,
} from "@suraasa/placebo-ui"
import api from "api"
import { ReviewStatus } from "api/resources/applications/types"
import clsx from "clsx"
import BackButton from "components/shared/BackButton"
import { InfoCircle } from "iconoir-react"
import React, { useContext, useState } from "react"
import { createUseStyles } from "react-jss"
import { useNavigate } from "react-router"
import { getApplicationTag } from "utils/helpers"
import { routes } from "utils/routes"
import toast from "utils/toast"
import { ApplicationContext } from "views/application/context/ApplicationContext"

import AskForChangesButton from "./AskForChangesButton"

const useStyles = createUseStyles(theme => ({
  titleBar: {
    width: "100%",
    background: "white",
    borderBottom: `1px solid ${theme.colors.onSurface[200]}`,
    overflowX: "auto",
  },
  bottomPadding: {
    paddingBottom: theme.spacing(7),
  },
  warning: {
    background: theme.colors.warning[50],
    borderTop: `1px solid ${theme.colors.warning[200]}`,
    borderBottom: `1px solid ${theme.colors.warning[200]}`,
  },
  critical: {
    background: theme.colors.critical[50],
    borderTop: `1px solid ${theme.colors.critical[200]}`,
    borderBottom: `1px solid ${theme.colors.critical[200]}`,
  },
  onSurface: {
    background: theme.colors.onSurface[50],
    borderTop: `1px solid ${theme.colors.onSurface[200]}`,
    borderBottom: `1px solid ${theme.colors.onSurface[200]}`,
  },
  success: {
    background: theme.colors.success[50],
    borderTop: `1px solid ${theme.colors.success[200]}`,
    borderBottom: `1px solid ${theme.colors.success[200]}`,
  },
  chipWarning: {
    background: `${theme.colors.warning[50]} !important`,
    border: `1px solid ${theme.colors.warning[400]} !important`,
  },
  chipOnSurface: {
    background: `${theme.colors.onSurface[50]} !important`,
    border: `1px solid ${theme.colors.onSurface[400]} !important`,
  },
  chipSuccess: {
    background: `${theme.colors.success[50]} !important`,
    border: `1px solid ${theme.colors.success[400]} !important`,
  },
  chipCritical: {
    background: `${theme.colors.critical[50]} !important`,
    border: `1px solid ${theme.colors.critical[400]} !important`,
  },
}))

const TitleBar = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const {
    applicantData,
    review,
    isSalesPerson,
    educationCheckList,
    personalCheckList,
    generalComments,
    crmIdentifiers,
  } = useContext(ApplicationContext)

  const name = `${applicantData.firstName} ${applicantData.lastName ?? ""}`

  const [acceptApplicant, setAcceptApplicant] = useState(false)
  const [rejectApplicant, setRejectApplicant] = useState(false)
  const [rejectionReason, setRejectionReason] = useState("")

  const toggleAcceptApplicantDialog = () => {
    setAcceptApplicant(!acceptApplicant)
    setRejectionReason("")
  }
  const toggleRejectApplicantDialog = () => {
    setRejectApplicant(!rejectApplicant)
    setRejectionReason("")
  }

  const updateApplicationStatus = async (newStatus: ReviewStatus) => {
    const res = await api.applications.interview.update({
      params: { review_id: review?.id || "" },
      data: { status: newStatus, ...(rejectionReason && { rejectionReason }) },
    })
    if (res.isSuccessful) {
      navigate(routes.dashboard, { replace: true })
    } else {
      console.error(res.errors)
      toast.error(res.errors.message)
    }
  }

  const tag = review
    ? getApplicationTag({
        reviewStatus: review.status,
        interviewStatus: review.interviewStatus,
      })
    : null

  const getTagDetails = () => {
    if (isSalesPerson) {
      return null
    }

    return (
      <div
        className={clsx({
          "flex items-center gap-1.5":
            review?.status === ReviewStatus.CHANGES_DONE,
        })}
      >
        {(review?.status === ReviewStatus.CHANGES_DONE ||
          review?.status === ReviewStatus.REVIEW_STARTED) && (
          <div className="flex items-center justify-center gap-3">
            <Button
              color="critical"
              variant="text"
              onClick={() => {
                setRejectApplicant(true)
              }}
            >
              Reject
            </Button>
            {educationCheckList.length > 0 ||
            personalCheckList.length > 0 ||
            generalComments.length > 0 ? (
              <AskForChangesButton size="sm" />
            ) : (
              <Button
                size="sm"
                onClick={() => {
                  setAcceptApplicant(true)
                }}
              >
                Accept Application
              </Button>
            )}
          </div>
        )}
        {review?.status !== ReviewStatus.REVIEW_STARTED && (
          <Chip
            className={clsx({
              [classes.chipWarning]: tag?.color === "warning",
              [classes.chipOnSurface]: tag?.color === "onSurface",
              [classes.chipSuccess]: tag?.color === "success",
              [classes.chipCritical]: tag?.color === "critical",
            })}
            label={tag?.text}
            variant="outlined"
          />
        )}
      </div>
    )
  }

  return (
    <>
      <div
        className={clsx(classes.titleBar, {
          [classes.warning]: tag?.color === "warning",
          [classes.onSurface]: tag?.color === "onSurface",
          [classes.success]: tag?.color === "success",
          [classes.critical]: tag?.color === "critical",
        })}
      >
        <div className="py-2">
          <Container>
            <div className="flex items-center justify-between">
              <div className="flex items-center justify-center gap-2">
                {!isSalesPerson && <BackButton />}
                <div className="hidden gap-1 md:flex">
                  <Typography color="onSurface.800" variant="title3">
                    {name}
                  </Typography>

                  {crmIdentifiers && (
                    <div className="flex items-center gap-1 ">
                      <Typography variant="body">
                        (Lead Owner - {crmIdentifiers.leadOwnerName})
                      </Typography>
                      <Tooltip
                        title={`${crmIdentifiers.leadOwnerName} (${crmIdentifiers?.leadOwnerEmail})`}
                      >
                        <InfoCircle />
                      </Tooltip>
                    </div>
                  )}
                </div>
              </div>
              {getTagDetails()}
            </div>
          </Container>
          <Container>
            <div className="mt-2 flex items-center justify-between gap-2 md:hidden">
              <Typography color="onSurface.800" variant="title3">
                {name}
              </Typography>
              {crmIdentifiers && (
                <div className="flex items-center justify-between gap-1">
                  <Typography variant="smallBody">
                    (Lead Owner - <br /> {crmIdentifiers.leadOwnerName})
                  </Typography>
                  <Tooltip
                    title={`${crmIdentifiers.leadOwnerName} (${crmIdentifiers?.leadOwnerEmail})`}
                  >
                    <InfoCircle />
                  </Tooltip>
                </div>
              )}
            </div>
          </Container>
        </div>
      </div>
      <div />
      <Dialog
        open={acceptApplicant}
        width="sm"
        onRequestClose={toggleAcceptApplicantDialog}
      >
        <DialogTitle>Accept Application</DialogTitle>
        <DialogContent className={classes.bottomPadding}>
          Are you sure you want to accept <strong>{name}’s</strong> application?
        </DialogContent>
        <DialogFooter
          actions={{
            primary: {
              label: "Accept",
              onClick: () => {
                updateApplicationStatus(ReviewStatus.INTERVIEW_REQUESTED)
              },
            },
            // secondary: {
            //   label: "Set up interview",
            //   onClick: () => {
            //     updateApplicationStatus(ReviewStatus.INTERVIEW_REQUESTED)
            //   },
            // },
          }}
        />
      </Dialog>
      <Dialog
        open={rejectApplicant}
        width="sm"
        onRequestClose={toggleRejectApplicantDialog}
      >
        <DialogTitle>Reject Application</DialogTitle>
        <DialogContent className={classes.bottomPadding}>
          <Typography className="mb-2">
            Are you sure you want to reject <strong>{name}’s</strong>{" "}
            application? If so please provide the reason:-
          </Typography>

          <TextField
            className="mb-1.5"
            placeholder="Type your reason here"
            rows={3}
            fullWidth
            multiLine
            onChange={(e: { currentTarget: { value: string } }) => {
              if (e.currentTarget.value.length) {
                setRejectionReason(e.currentTarget.value)
              }
            }}
          />
        </DialogContent>

        <DialogFooter
          actions={{
            primary: {
              label: "Reject",
              onClick: () => {
                updateApplicationStatus(ReviewStatus.REJECTED)
              },
              color: "critical",
            },
            secondary: {
              label: "Cancel",
              onClick: toggleRejectApplicantDialog,
            },
          }}
        />
      </Dialog>
    </>
  )
}

export default TitleBar
