export type Country = {
  id: number
  uuid: string
  name: string
  isoCode: string
  isoCode3: string
}

export type Language = {
  uuid: string
  name: string
  localeCode: string
  nativeName: string
}

export type Subject = {
  uuid: string
  name: string
  addedById: string | null
  isApproved: boolean
}

export type State = {
  id: number
  uuid: string
  name: string
  isoCode: string
  countryId: Country["uuid"]
}

export type Currency = {
  uuid: string
  name: string
  symbol: string
  symbolNative: string
  code: string
  countryId: string
}

export type Curricula = {
  isDefault: boolean | null
  addedById: string
  isApproved: boolean
  uuid: string
  name: string
}

export type TeachingLevel = {
  name: string
  uuid: string
}

export type TeachingRole = {
  name: string
  uuid: string
}

export type QualificationField = {
  id: number
  uuid: string
  name: string
  addedById: string | null
  isApproved: boolean
}
export enum QuestionType {
  SINGLE_SELECT = 1,
  MULTIPLE_SELECT,
}

export enum AttachmentType {
  MARKSHEET = 1,
  DEGREE,
}

export type QualificationLevel = {
  uuid: string
  name: string
}

export enum ModeOfLearning {
  ONLINE = 1,
  OFFLINE,
  BLENDED,
  DEFAULT,
}
export enum OrganisationType {
  SCHOOL = 1,
  COLLEGE,
  TUTORING,
}

export enum EmploymentType {
  FULL_TIME = 1,
  PART_TIME,
  FRESHER,
  INTERN,
  FREELANCE,
  SELF_EMPLOYED,
}

export type Curriculum = {
  uuid: string
  name: string
  isDefault: boolean
  isApproved: boolean
  addedById: string | null
}

export enum LanguageProficiencyChoices {
  ELEMENTARY = 1,
  LIMITED_WORKING,
  PROFESSIONAL_WORKING,
  FULL_PROFESSIONAL,
  NATIVE,
}
