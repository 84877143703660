import api from "api"
import {
  ApplicantDetails,
  Certification,
  CommentType,
  GeneralCommentType,
  LanguageProficiency,
  PersonalDetails,
  Qualification,
  Reference,
  ReviewChangeItemType,
  ReviewStatus,
  ReviewStatusType,
  WorkExperience,
} from "api/resources/applications/types"
import { Country, LanguageProficiencyChoices } from "api/resources/global/types"
import {
  createContext,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react"
import { useNavigate } from "react-router-dom"
import { SLUGS } from "utils/applicationForm"
import { routes } from "utils/routes"

export enum GeneralCommentSections {
  EDUCATIONAL = "educational",
  EMPLOYMENT = "employment",
}

type GeneralComments = {
  type: GeneralCommentSections
  comment: GeneralCommentType
}

export type ApplicationContextType = {
  isReviewDisabled: boolean
  applicationId: string
  personalCheckList: CommentType[]
  employmentCheckList: CommentType[]
  setEmploymentCheckList: React.Dispatch<SetStateAction<CommentType[]>>
  setPersonalCheckList: React.Dispatch<SetStateAction<CommentType[]>>
  educationCheckList: CommentType[]
  setEducationCheckList: React.Dispatch<SetStateAction<CommentType[]>>
  removeGeneralComment: (type: GeneralComments["type"]) => void
  generalComments: GeneralComments[]
  updateGeneralComment: (
    type: GeneralComments["type"],
    comment: GeneralComments["comment"]
  ) => void
  getGeneralCommentByType: (type: GeneralComments["type"]) => string
  reviewList: CommentType[]
  applicantData: PersonalDetails
  questionList: ApplicantDetails["questionnaireQuestions"]
  languageProficiencies: LanguageProficiency[]
  qualifications: Qualification[]
  certifications: Certification[]
  workExperiences: WorkExperience[]
  references: Reference[]
  countries: Country[]
  review: ReviewStatusType | null
  toggleLoading: (state: boolean) => void
  isSalesPerson?: boolean
  crmIdentifiers: ApplicantDetails["crmIdentifiers"] | null
}

export const proficiencyChoices = [
  {
    label: "Elementary",
    value: LanguageProficiencyChoices.ELEMENTARY,
  },
  {
    label: "Limited Working",
    value: LanguageProficiencyChoices.LIMITED_WORKING,
  },
  {
    label: "Professional Working",
    value: LanguageProficiencyChoices.PROFESSIONAL_WORKING,
  },
  {
    label: "Full Professional",
    value: LanguageProficiencyChoices.FULL_PROFESSIONAL,
  },
  {
    label: "Native/Bi-lingual",
    value: LanguageProficiencyChoices.NATIVE,
  },
]

export const ApplicationContext = createContext<ApplicationContextType>({
  review: null,

  isReviewDisabled: false,
  generalComments: [],
  removeGeneralComment: () => {},
  updateGeneralComment: () => {},
  getGeneralCommentByType: () => "",
  applicationId: "",
  personalCheckList: [],
  setPersonalCheckList: () => {},
  educationCheckList: [],
  employmentCheckList: [],
  setEmploymentCheckList: () => {},
  setEducationCheckList: () => {},
  reviewList: [],
  applicantData: {
    id: 0,
    title: null,
    firstName: "",
    lastName: "",
    email: "",
    dateOfBirth: null,
    gender: undefined,
    nationalIdNumber: null,
    nationalityId: null,
    mobilePhoneNumber: null,
    photo: undefined,
    passportNumber: null,
    whatsappPhoneNumber: null,
    homePhoneNumber: null,
    workPhoneNumber: null,
    currentResidence: undefined,
    permanentResidence: undefined,
    permanentSameAsCurrent: 0,
    addresses: [],
  },
  questionList: [],
  languageProficiencies: [],
  qualifications: [],
  certifications: [],
  workExperiences: [],
  references: [],
  countries: [],
  toggleLoading: () => {},
  isSalesPerson: false,
  crmIdentifiers: null,
})

export const ApplicationContextProvider = ({
  toggleLoading,
  applicationId,
  children,
  isSalesPerson,
}: {
  toggleLoading: (state: boolean) => void
  applicationId: string
  isSalesPerson?: boolean
  children: React.ReactNode
}) => {
  const [personalCheckList, setPersonalCheckList] = useState<CommentType[]>([])
  const [educationCheckList, setEducationCheckList] = useState<CommentType[]>(
    []
  )
  const [employmentCheckList, setEmploymentCheckList] = useState<CommentType[]>(
    []
  )
  const [applicantData, setApplicantData] = useState<PersonalDetails>({
    id: 0,
    title: null,
    firstName: "",
    lastName: "",
    email: "",
    dateOfBirth: null,
    gender: undefined,
    nationalIdNumber: null,
    nationalityId: null,
    mobilePhoneNumber: null,
    photo: undefined,
    passportNumber: null,
    whatsappPhoneNumber: null,
    homePhoneNumber: null,
    workPhoneNumber: null,
    currentResidence: undefined,
    permanentResidence: undefined,
    permanentSameAsCurrent: 0,
    addresses: [],
  })
  const [crmIdentifiers, setCrmIdentifiers] = useState<
    ApplicantDetails["crmIdentifiers"] | null
  >(null)

  const navigate = useNavigate()

  const [questionList, setQuestionList] = useState<
    ApplicantDetails["questionnaireQuestions"]
  >([])
  const [reviewList, setReviewList] = useState<CommentType[]>([])
  const [languageProficiencies, setLanguageProficiencies] = useState<
    LanguageProficiency[]
  >([])
  const [qualifications, setQualifications] = useState<Qualification[]>([])
  const [certifications, setCertifications] = useState<Certification[]>([])
  const [workExperiences, setWorkExperiences] = useState<WorkExperience[]>([])
  const [references, setReferences] = useState<Reference[]>([])

  const [review, setReview] = useState<ReviewStatusType | null>(null)

  const [generalComments, setGeneralComments] = useState<GeneralComments[]>([])

  useEffect(() => {
    const getApplicationStatus = async () => {
      const res = await api.applications.review.getOrCreate({
        urlParams: {
          applicationId,
        },
      })
      if (res.isSuccessful) {
        setReview(res.data)
      }
    }
    getApplicationStatus()
  }, [applicationId])

  const [countries, setCountries] = useState<Country[]>([])

  const setPersonalDetailsData = (data: ApplicantDetails) => {
    const permanentSameAsCurrent = data.addresses.every(
      ({ isCurrent, isPermanent }) => isCurrent && isPermanent
    )
    const currentAddress = data.addresses.find(({ isCurrent }) => isCurrent)
    const permaAddress = permanentSameAsCurrent
      ? null
      : data.addresses.find(({ isPermanent }) => isPermanent)

    setApplicantData({
      ...data.applicationDetail,
      addresses: [...data.addresses],
      ...(currentAddress && { currentResidence: { ...currentAddress } }),
      ...(permaAddress && { permanentResidence: { ...permaAddress } }),
      ...{
        permanentSameAsCurrent: permanentSameAsCurrent
          ? SLUGS.step2.permanentSameAsCurrent.options.yes
          : SLUGS.step2.permanentSameAsCurrent.options.no,
      },
    })
  }

  useEffect(() => {
    const getData = async () => {
      toggleLoading(true)
      const res = await api.applications.retrieve({
        urlParams: { applicationId },
        params: {
          fields: ["crm_identifiers"],
        },
      })
      if (res.isSuccessful) {
        if (res.data.productSlug === "masters-in-education") {
          navigate(routes.medReview.replace(":applicationId", applicationId), {
            replace: true,
          })
        }
        setCrmIdentifiers(res.data.crmIdentifiers)
        setPersonalDetailsData(res.data)
        if (res.data.questionnaireQuestions) {
          setQuestionList(res.data.questionnaireQuestions)
        }
        setReferences(res.data.references)
      }
      toggleLoading(false)
    }
    const getProfileData = async () => {
      const res = await api.applications.retrieveProfile({
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        urlParams: { applicationId },
        params: {
          fields: [
            "language_proficiencies",
            "qualifications",
            "certifications",
            "work_experiences",
          ],
        },
      })
      if (res.isSuccessful) {
        setLanguageProficiencies(res.data.languageProficiencies)
        setQualifications(res.data.qualifications)
        setCertifications(res.data.certifications)
        setWorkExperiences(res.data.workExperiences)
      }
      toggleLoading(false)
    }
    if (applicationId) {
      getData()
      getProfileData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicationId])

  const isReviewDisabled = useCallback(() => {
    if (reviewList.length > 0) return true

    if (!review) return true

    switch (review.status) {
      case ReviewStatus.NOT_STARTED:
      case ReviewStatus.REVIEW_STARTED:
      case ReviewStatus.CHANGES_DONE: // If user has done the changes, we are allowed to review again.
        return false

      default:
        return true
    }
  }, [review, reviewList])

  useEffect(() => {
    const getReviewList = (item: CommentType[]) => {
      const applicationData = item.filter(
        i => i.itemType === ReviewChangeItemType.applicationdetail
      )
      const educationData = item.filter(
        i =>
          i.itemType === ReviewChangeItemType.qualification ||
          i.itemType === ReviewChangeItemType.certification
      )
      const employmentData = item.filter(
        i =>
          i.itemType === ReviewChangeItemType.workexperience &&
          Boolean(i.itemId)
      )
      setPersonalCheckList(applicationData)
      setEducationCheckList(educationData)
      setEmploymentCheckList(employmentData)
    }

    const getReviewChangeList = async () => {
      const res = await api.applications.review.list({
        params: { application_id: applicationId },
      })
      if (res.isSuccessful) {
        setReviewList(res.data)
        if (res.data.length > 0) {
          getReviewList(res.data)
        }
      } else {
        console.error(res.errors)
      }
    }

    const getData = async () => {
      const countriesRes = await api.global.listCountries({
        params: { page: "-1" },
      })

      if (countriesRes.isSuccessful) setCountries(countriesRes.data)
    }
    getData()
    getReviewChangeList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const updateReviewStatus = () => {
      api.applications.review.updateStatus({
        params: { review_id: review?.id || "" },
        data: { status: ReviewStatus.REVIEW_STARTED },
      })
    }
    if (review && review.status === ReviewStatus.CHANGES_DONE) {
      updateReviewStatus()
    }
  }, [review])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateGeneralComment = (
    type: GeneralComments["type"],
    comment: GeneralComments["comment"]
  ) => {
    setGeneralComments(comments => [
      ...comments.filter(comment => comment.type !== type),
      {
        type,
        comment,
      },
    ])
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const removeGeneralComment = (type: GeneralComments["type"]) => {
    setGeneralComments(comments =>
      comments.filter(comment => comment.type !== type)
    )
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getGeneralCommentByType = (type: GeneralComments["type"]) => {
    return (
      generalComments.find(v => v.type === type && v.comment.itemId === null)
        ?.comment.comment || ""
    )
  }
  const contextValues = useMemo(
    () => ({
      personalCheckList,
      setPersonalCheckList,
      educationCheckList,
      employmentCheckList,
      setEmploymentCheckList,
      setEducationCheckList,
      applicationId,
      applicantData,
      questionList,
      reviewList,
      languageProficiencies,
      qualifications,
      certifications,
      workExperiences,
      references,
      countries,
      review,
      isReviewDisabled: isReviewDisabled(),
      toggleLoading,
      isSalesPerson,
      generalComments,
      updateGeneralComment,
      removeGeneralComment,
      getGeneralCommentByType,
      crmIdentifiers,
    }),
    [
      personalCheckList,
      educationCheckList,
      employmentCheckList,
      applicationId,
      applicantData,
      questionList,
      reviewList,
      languageProficiencies,
      qualifications,
      certifications,
      workExperiences,
      references,
      countries,
      review,
      isReviewDisabled,
      toggleLoading,
      isSalesPerson,
      generalComments,
      updateGeneralComment,
      removeGeneralComment,
      getGeneralCommentByType,
      crmIdentifiers,
    ]
  )
  return (
    <ApplicationContext.Provider value={contextValues}>
      {children}
    </ApplicationContext.Provider>
  )
}
