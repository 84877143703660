import { CssBaseline, theme } from "@suraasa/placebo-ui"
import { checkStorageSupport, SessionStorageBanner } from "@suraasa/utils"
import AdminRoute from "components/AdminRoute"
import GetActions from "components/auth/GetActions"
import AdminDashboard from "components/dashboard/Dashboard"
import GlobalState from "components/GlobalState"
import SalesRoute from "components/SalesRoute"
import ErrorPage from "components/shared/ErrorPage"
import React from "react"
import { ThemeProvider } from "react-jss"
import {
  BrowserRouter as Router,
  Navigate,
  Outlet,
  Route,
  Routes,
} from "react-router-dom"
import { getAuthInfo } from "utils/auth"
// import SalesRoute from "components/shared/SalesRoute"
import { routes } from "utils/routes"
import ToastProvider from "utils/toast/ToastProvider"
import MEdReview from "views/application/masters-in-education"
import ApplicationReview from "views/application/Review"
import SSO from "views/auth/SSO"
import SentryTest from "views/SentryTest"

const AuthRoutes = () => {
  const auth = getAuthInfo()

  return auth ? (
    <Outlet />
  ) : (
    <Navigate
      to={{
        pathname: routes.login,
        search: `?next=${window.location.pathname}`,
      }}
    />
  )
}

function App() {
  const isStorageSupported = checkStorageSupport()

  return (
    <Router>
      {/* @ts-ignore Can't seem to fix this */}
      <ThemeProvider theme={theme}>
        <ToastProvider domRoot={document.body}>
          <CssBaseline />
          {!isStorageSupported && <SessionStorageBanner />}
          <Routes>
            <Route element={<SSO />} path={routes.login} />

            <Route element={<SentryTest />} path={routes.error} />

            <Route element={<AuthRoutes />}>
              <Route element={<GlobalState />} path="/">
                <Route element={<GetActions />} path="/">
                  <Route
                    element={<Navigate to={routes.dashboard} replace />}
                    path="/"
                  />
                  <Route element={<MEdReview />} path={routes.medReview} />
                  <Route
                    element={<ApplicationReview />}
                    path={routes.applicationReview}
                  />

                  <Route element={<SalesRoute />} path="/">
                    {/* Any routes which are only for sales person go here */}
                  </Route>
                  <Route element={<AdminRoute />} path="/">
                    {/* Any routes which are only for admin go here */}
                    <Route
                      element={<AdminDashboard />}
                      path={routes.dashboard}
                    />
                  </Route>
                </Route>
              </Route>

              <Route element={<ErrorPage errorCode={404} />} path="*" />
            </Route>

            <Route element={<ErrorPage errorCode={404} />} path="*" />
          </Routes>
        </ToastProvider>
      </ThemeProvider>
    </Router>
  )
}

export default App
