import { applicationFormSlugs } from "utils/constants"

import {
  AttachmentType,
  Country,
  Currency,
  Curriculum,
  EmploymentType,
  Language,
  ModeOfLearning,
  OrganisationType,
  QualificationField,
  QualificationLevel,
  State,
  TeachingLevel,
  TeachingRole,
} from "../global/types"

export enum ApplicationStatus {
  NOT_STARTED = 1,
  IN_PROGRESS,
  SUBMITTED,
  UNDER_REVIEW,
  ACCEPTED,
  REJECTED,
}

export enum ReviewStatus {
  NOT_STARTED = 1,
  REVIEW_STARTED,
  CHANGES_REQUESTED,
  CHANGES_STARTED,
  CHANGES_DONE,
  ACCEPTED,
  REJECTED,
  INTERVIEW_REQUESTED,
}

export enum InterviewStatus {
  SCHEDULED = 1,
  NO_SHOW,
  CANCELLED,
  ACCEPTED,
  REJECTED,
}

export enum LanguageProficiencyChoices {
  ELEMENTARY = 1,
  LIMITED_WORKING,
  PROFESSIONAL_WORKING,
  FULL_PROFESSIONAL,
  NATIVE,
}

export enum AdditionalAnswerType {
  TEXT = 1,
  EMAIL,
  REFERENCE,
}
export enum QuestionType {
  SINGLE_SELECT = 1,
  MULTIPLE_SELECT,
}

export type Users = {
  id: number
  status: ApplicationStatus
  submitTime: string | null
  review: {
    id: number
    status: ReviewStatus
  }
  interview: {
    id: number
    status: InterviewStatus
  } | null
  evidenceUploaded: boolean
  applicationDetail: {
    id: number
    firstName: string
    lastName: string
    email: string
    photo: string | null
  } | null
  productSlug: ApplicationTypeSlug
}

export type PhoneNumber = {
  countryCode: number
  number: number
}

export type Address = {
  id: number
  streetAddress: string
  country: Country
  state: State
  city: string
  pincode: string
  isCurrent: boolean
  isPermanent: boolean
}

export type Reference = {
  id: number
  phoneNumber: PhoneNumber
  name: string
  email: string
  relationship: string | null
  jobTitle: string | null
  workPlace: string | null
}

export type Option = {
  id: number
  text: string
  order: number
  additionalAnswerRequired: boolean
  additionalAnswerType: AdditionalAnswerType | null
}
export type Response = {
  id: number
  option: number
  additionalAnswer: string
  additionalAnswerFile?: string
}

export type Question = {
  id: number
  text: string
  slug: string
  questionType: QuestionType
  category: string
  isRequired: boolean
  options: Option[]
  responses: Response[]
}

export type PersonalDetails = {
  id: number
  title: number | null
  firstName: string
  lastName: string
  email: string
  dateOfBirth: string | null
  gender: string | undefined
  nationalIdNumber: string | null
  nationalityId: string | null
  mobilePhoneNumber: PhoneNumber | null
  photo: string | undefined
  passportNumber: string | null
  whatsappPhoneNumber: PhoneNumber | null
  homePhoneNumber: PhoneNumber | null
  workPhoneNumber: PhoneNumber | null
  currentResidence?: Address
  permanentResidence?: Address
  permanentSameAsCurrent: number
  addresses: Address[]
}
export type ApplicantDetails = {
  id: number
  crmIdentifiers: {
    leadOwnerName: string
    leadOwnerEmail: string
  } | null
  attemptNumber: number
  productSlug: string
  addresses: Address[]
  references: Reference[]
  questionnaireQuestions: {
    questionNumber: number
    question: Question
  }[]
  applicationDetail: PersonalDetails
  submitTime?: string | null
}

export type ValueOf<T> = T[keyof T]

export type EducationDetails = {
  course: string
  qualification: string | null
  domain: string | null
  institute: string
  city: string | null
  country: string | null
  startDate: string
  endDate: string | null
  qualificationFormat?: QualificationFormat
  evidences: Evidence[]
}[]

export enum QualificationFormat {
  REGULAR = 1,
  DISTANCE,
}

export enum ReviewChangeItemType {
  applicationdetail = "applicationdetail",
  applicationAddress = "applicationaddress",
  qualification = "academicqualification",
  certification = "academiccertification",
  workexperience = "workexperience",
  educationalbackground = "educationalbackground",
  languageproficiency = "languageproficiency",
}

export type CommentType = {
  itemId: number | null
  itemType: ReviewChangeItemType
  fieldName?: string
  comment: string | null
  error?: string
}

export type GeneralCommentType = {
  itemId: null
  itemType: ReviewChangeItemType
  comment: string | null
}

export type CheckListType = {
  personal?: CommentType[]
  education?: CommentType[]
  employment?: CommentType[]
}

export enum EvidenceType {
  URL = 1,
  File,
}

type BaseEvidence = {
  id: string
}

export type Evidence =
  | (BaseEvidence & {
      evidenceType: EvidenceType.URL
      url: string
    })
  | (BaseEvidence & {
      evidenceType: EvidenceType.File
      file: string
      fileName: string
    })

export type ReviewStatusType = {
  id: number
  status: ReviewStatus
  interviewStatus: InterviewStatus
}

export type Certificate = {
  id: number
  attachment: string | null
  attachmentName: string | null
  attachmentType: AttachmentType | null
  url: string | null
}

export type LanguageProficiency = {
  id: number
  language: Language
  proficiency: LanguageProficiencyChoices
  hasEvidence: boolean
  certificateName: string | null // Null if has evidence is false
  certificateIssuerName: string | null // Null if has evidence is false
  proficiencyScore: number | null // Null if has evidence is false
  attachmentName: string | null // Null if has evidence is false
  attachment: string | null // Null if has evidence is false
  url: string | null // Null if has evidence is false
  suraasaId: number | null
}

export type Qualification = {
  id: number
  name: string
  instituteName: string
  certificates: Certificate[]
  qualificationLevel: QualificationLevel
  qualificationField: QualificationField
  qualificationFormat?: QualificationFormat
  country: Country | null
  state: State | null
  grade: string | null
  modeOfLearning: ModeOfLearning
  startDate: string
  endDate: string
  suraasaId: number | null
}

export type Certification = {
  id: number
  name: string
  instituteName: string
  canExpire: boolean
  completionDate: string
  expirationDate: string | null
  modeOfLearning: ModeOfLearning
  country: Country | null
  state: State | null
  certificates: Certificate[]
  suraasaId: null
}

export type WorkExperience = {
  id: number
  title: string
  currentlyWorking: boolean
  startDate: string
  endDate: string | null
  subjects: {
    subjectId: string
  }[]
  employmentType: EmploymentType
  description: string | null
  organisationName: string
  organisationType: OrganisationType | null
  otherOrganisationType: string | null
  curriculum: Curriculum | null
  teachingLevel: TeachingLevel | null
  teachingRole: TeachingRole | null
  country: Country | null
  state: State | null
  currency: Currency | null
  salary: number
  suraasaId: number | null
}

export type ProfileDataType = {
  languageProficiencies: LanguageProficiency[]
  qualifications: Qualification[]
  certifications: Certification[]
  workExperiences: WorkExperience[]
}

export type Overview = {
  newApplications: number
  inProgressApplications: number
}

export type ApplicationTypeSlug = ValueOf<typeof applicationFormSlugs>
