import { useContext } from "react"
import { Outlet } from "react-router-dom"
import { ACTIONS } from "utils/action"

import { GlobalContext } from "./GlobalState"
import ErrorPage from "./shared/ErrorPage"

function SalesRoute() {
  const { actions } = useContext(GlobalContext)

  return !actions.includes(ACTIONS.assignApplication) ? (
    <Outlet />
  ) : (
    <ErrorPage errorCode={403} />
  )
}

export default SalesRoute
