import api from "api"
import { GlobalContext } from "components/GlobalState"
import LoadingOverlay from "components/shared/LoadingOverlay"
import React, { useContext, useEffect, useState } from "react"
import { Outlet } from "react-router-dom"
import { getAuthInfo } from "utils/auth"

const GetActions = () => {
  const [loading, setLoading] = useState(true)
  const { setActions } = useContext(GlobalContext)

  useEffect(() => {
    const listActions = async () => {
      const res = await api.users.userActions.list()
      if (res.isSuccessful) {
        setActions(res.data)
        console.log(res.data)
      }
      setLoading(false)
    }

    if (getAuthInfo()) {
      listActions()
    } else setLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (loading) {
    return <LoadingOverlay />
  }

  // if (error) {
  //   return <ErrorScreen error={error} />
  // }
  return <Outlet />
}

export default GetActions
